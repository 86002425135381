import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = (props) => {
  const {
    location,
    layout: Layout,
    authPath,
    component: Component, ...rest
  } = props;
  const { user } = useSelector((state) => state.userAuth);

  return (
    <Route
      {...rest}
      render={(props) => (user?.id ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: authPath,
            state: props.location
          }}
        />
      ))}
    />
  );
};

PrivateRoute.prototype = {
  location: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};

export default PrivateRoute;
