import React from 'react';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import { Settings } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import AccountBox from '@material-ui/icons/Input';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import UserUtils from '../../../utils/functions/users/userUtils';

const navPath = [
  {
    title: 'Settings',
    icon: <Settings />,
    href: '/dashboard'
  }
];

const DrawerView = ({
  classes, theme, user,
  handleDrawerClose, open
}) => {
  const { userLogout } = UserUtils;
  const history = useHistory();
  return (
    <Drawer
      variant={user ? 'permanent' : 'temporary'}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {navPath.map(({ title, icon, href }) => (
          <Link key={title} to={href}>
            <ListItem
              button
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={(e) => userLogout(e, history)} key="logout">
          <ListItemIcon><AccountBox /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default DrawerView;
