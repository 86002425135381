import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import appRoutes from '../routes/routes.path';

const {
  CE_LOGIN,
  MC_LOGIN,
  USER_LOGIN,
  USER_REGISTER
} = appRoutes;
const routes = [
  CE_LOGIN,
  MC_LOGIN,
  USER_LOGIN,
  USER_REGISTER
];

const forcedRedirect = (path) => routes.includes(path);

const RouteWithLayouts = (props) => {
  const {
    path,
    layout: Layout,
    component: Component,
    ...rest
  } = props;
  const { user } = useSelector((state) => state.userAuth);

  if (forcedRedirect(path) && user?.id) {
    return (
      <Redirect
        to="/"
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        Layout
          ? (
            <Layout>
              <Component
                {...props}
              />
            </Layout>
          ) : (
            <Component
              {...props}
            />
          )
      )}
    />
  );
};

RouteWithLayouts.prototype = {
  location: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired
};

export default RouteWithLayouts;
