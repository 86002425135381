export const Style = (theme) => ({
  root: {
    margin: theme.spacing(1, 'auto', 5, 'auto'),
  },
  paper: {
    margin: theme.spacing(8, 0, 0),
    padding: theme.spacing(3, 3, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1, 0, 0),
    height: '4rem',
    width: '4rem',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(1, 0, 0)
  },
  textField: {
    marginTop: theme.spacing(2)
  },

  link: {
    margin: theme.spacing(1.3, 1),
    '& a': {
      color: theme.palette.secondary.main,
      fontWeight: '700',
      margin: theme.spacing(0, 1),
    }
  },
});
