/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Avatar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { searchedResultStyles } from './Style';
import ProgressLoader from '../backdrop/Loading';
import { EmptyList } from '../EmptyList/EmptyList';
import productImg from '../../images/category.svg';
import { getProducts } from '../../redux/reducers/Product';

const useStyles = makeStyles((theme) => (searchedResultStyles(theme)));

export default function SearchResults({
  query,
  breathe,
  response,
  data = [],
  urls, isLoading
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (response?.data?.docs) {
      dispatch(getProducts(response));
    }
  },);

  const DataList = () => (
    <>
      {!data.length ? (
        <EmptyList
          imageFile={productImg}
          text="No Product Found"
          color="secondary"
        />
      ) : (
        <>
          <List className={classes.root}>
            {data.slice(0, 5).map((item) => (
              <Link
                key={item._id}
                onClick={() => setOpen(!open)}
                to={`${urls?.details}/${item.category}/${item.title}/${item._id}`}
              >
                <ListItem>
                  <ListItemAvatar
                    className={classes.list}
                  >
                    <Avatar
                      height="45"
                      variant="square"
                      src={item.image}
                      alt={item.title}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.text}
                    primary={item.title}
                  />
                </ListItem>
                <Divider />
              </Link>
            ))}
          </List>
          <Link to={`${urls?.all}?q=${query}`} className={classes.link}>
            {data && data.length ? `SEE ALL ${data.length} RESULTS` : 'SEE ALL PRODUCTS'}
          </Link>
        </>
      )}
    </>
  );

  return (
    <>
      {open && (
        <div className={open ? classes.root : classes.hide}>
          <Paper
            elevation={3}
            className={classes.paper}
            style={{ width: breathe }}
          >
            <h4>
              Search Results
              {' '}
              {data.length}
            </h4>
            <CloseIcon
              onClick={handleClose}
              className={classes.close}
            />
            {
              isLoading
                ? <ProgressLoader height="50" />
                : <DataList />
            }
          </Paper>
        </div>
      )}
    </>
  );
}

SearchResults.defaultProps = {
  data: []
};
