import React, { useEffect } from 'react';
import DesktopLinks from './Links';
import Logo from '../Logo/Logo';
import { SearchQuery, Shopping } from '../../../GoogleAnalytics/Events/Buying';

const DesktopNavHeader = ({
  customLogo,
  shopUrl, routes,
  CartDrawer, searchBar, homeUrl
}) => {
  useEffect(() => {
    Shopping();
    SearchQuery();
  }, []);

  return (
    <nav className="largenav">
      <div className="largenav-logo">
        <Logo
          homeUrl={homeUrl}
          customLogo={customLogo}
        />
      </div>
      <div className="largenav-search">
        {searchBar}
      </div>
      <div className="largenav-links">
        <DesktopLinks
          shopUrl={shopUrl}
          routes={routes}
        />
        {CartDrawer}
      </div>
    </nav>
  );
};

export default DesktopNavHeader;
