import React from 'react';
import { Link } from 'react-router-dom';
import {
  PersonOutline as PersonOutlineIcon,
} from '@material-ui/icons/';
import Logo from '../Logo/Logo';
import SideNav from './sidenav/SideNav';

const NavBar = ({
  customLogo,
  loggedInUserNav,
  loggedOutUserNav, routes,
  cartDrawer, searchBar
}) => {
  const { home, userProfile } = routes;
  return (
    <div className="navbar">
      <div className="navbar-grid">
        <div style={{ margin: '0  0 0 1rem ' }} className="logo">
          <Logo
            homeUrl={home}
            customLogo={customLogo}
          />
        </div>
        <ul className="nav-box">
          <li className="navbar-user">
            <Link to={userProfile}>
              <PersonOutlineIcon />
            </Link>
          </li>
          <li className="navbar-cart">
            {cartDrawer}
          </li>
          <li>
            <SideNav
              routes={routes}
              loggedInUserNav={loggedInUserNav}
              loggedOutUserNav={loggedOutUserNav}
            />
          </li>
        </ul>
      </div>
      <div className="nav-search-bar">
        {searchBar}
      </div>
    </div>
  );
};

export default NavBar;
