import React from 'react';
import PhoneInput from 'react-phone-number-input';

const PhoneNumberInput = ({
  className,
  maxLength,
  phoneNumber,
  setPhoneNumber,
  limitMaxLength,
  defaultCountry,
  ...rest
}) => {
  return (

    <PhoneInput
      {...rest}
      required
      value={phoneNumber}
      maxLength={maxLength}
      className={className}
      onChange={setPhoneNumber}
      country="NG"
      placeholder="Enter Phone Number"
      defaultCountry={defaultCountry}
      limitMaxLength={limitMaxLength}
    />
  );
};

PhoneNumberInput.defaultProps = {
  className: 'phone-input',
  maxLength: 13,
  limitMaxLength: 13,
  defaultCountry: 'NG'
};

export default PhoneNumberInput;
