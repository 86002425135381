export const Style = (theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1, 'auto')
  },
  block: {
    display: 'block',
    margin: theme.spacing(1),
    textTransform: 'capitalize'
  }
});
