import { Button, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import {
  Search as SearchIcon
} from '@material-ui/icons/';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import SearchResults from './SearchResults';
import Style from './Style';
import useDebounce from '../../hooks/useDebounce';
import { BASE_URL } from '../../services/config';
import useGetQuery from '../../hooks/useGetQuery';
import { getProducts } from '../../redux/reducers/Product';

const useStyles = makeStyles((theme) => Style(theme));

const SearchBar = ({ breathe, urls }) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [openState, setOpenState] = useState(false);
  const { debouncedValue } = useDebounce(searchQuery, 500);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    data: res, isLoading, refetch
  } = useGetQuery({
    baseUrl: BASE_URL.COOPERATIVE_AUTH,
    endpoint: `/products?q=${searchQuery}`,
    queryKey: 'searched-products',
    options: {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
    }
  });

  const onSearch = useCallback(async () => {
    refetch();
    setOpenState(!openState);
  }, [openState, refetch]);

  const querySearch = (e) => {
    e.preventDefault();
    onSearch();
    dispatch(getProducts(res));
    history.push(`products/all?q=${searchQuery}`);
  };

  useEffect(() => {
    if (debouncedValue) {
      onSearch();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div>
      <form onSubmit={querySearch}>
        <input
          required
          type="text"
          name="search-text"
          placeholder="search for food items"
          onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
        />
        <button className={classes.searchBtnSmall} type="submit">
          <SearchIcon fontSize="inherit" />
        </button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.searchBtnLarge}
        >
          SEARCH
        </Button>
      </form>
      <div>
        {openState && (
          <SearchResults
            urls={urls}
            response={res}
            breathe={breathe}
            query={searchQuery}
            isLoading={isLoading}
            data={res?.data?.docs}
          />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
