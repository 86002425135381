import React from 'react';
import {
  Button, TextField,
  Typography, Link
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const LoginForm = ({
  classes,
  formState,
  loading,
  handleLogin,
  handleChange
}) => {
  return (
    <>
      <TextField
        className={classes.textField}
        fullWidth
        label="Email address"
        name="email"
        onChange={handleChange}
        type="email"
        required
        value={formState.email || ''}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        fullWidth
        required
        label="Password"
        name="password"
        onChange={handleChange}
        type="password"
        value={formState.password || ''}
        variant="outlined"
      />
      <Button
        className={classes.textField}
        color="primary"
        disabled={!!loading}
        onClick={handleLogin}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        Sign in now
      </Button>
    </>
  );
};

export const LoginFormFooter = ({ classes }) => (
  <Typography
    className={classes.link}
    color="textSecondary"
    variant="body2"
  >
    Forgot Password?
    <Link
      component={RouterLink}
      to="/cooperatives/forgot-password"
      variant="body2"
    >
      click here
    </Link>
  </Typography>
);

export default LoginForm;
