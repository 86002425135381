import { createSlice } from '@reduxjs/toolkit';
import palette from '../../../theme/Palette';
import _ from '../../../utils/functions/app/AppUtils';
import { PLATFORM_STORE, PLATFORM_CONFIG } from '../../../utils/data/constants/constants';

const defaultConfig = {
  theme: {
    primary: {
      main: '#ffc400'
    },
    secondary: {
      main: '#008080'
    },
    ...palette
  }
};

const platforms = _.getLocalStoreItem({ name: PLATFORM_STORE, type: [] });
const getThemeConfig = _.getLocalStoreItem({
 name: PLATFORM_CONFIG,
   type: defaultConfig
});

const getFoodCrowdy = () => platforms.find((item) => (
  item.name === 'foodcrowdy'
  ));

const initialState = {
  app: {
    ...getFoodCrowdy(),
    ...getThemeConfig
  },
  error: '',
};

const platform = createSlice({
  initialState,
  name: 'platform',
  reducers: {
    getPlatform(state, action) {
      const { config } = action.payload;
      const appConfig = {
        ...config,
        theme: {
          ...config.theme,
          ...palette
        }
      };
      state.app = appConfig;
      _.saveLocalStoreItem({ name: PLATFORM_CONFIG, data: appConfig });
      return state;
    },
    onError(state, action) {
      const error = action.payload;
      state.error = error;
      return state;
    }
  }
});

export const { onError, getPlatform } = platform.actions;

export default platform.reducer;
