import React from 'react';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import { makeStyles } from '@material-ui/core/styles';
import {
  appBrands
} from '../../constants/Brands';

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: theme.spacing(-1, 0.4, -0.4, 0),
    fontSize: '1rem'
  },
  address: {
    fontStyle: 'normal',
    fontSize: '.7rem',
    '& ul > li': {
      margin: theme.spacing(0.5, 0),
    }
  }
}));

const ContactInfo = () => {
  const classes = useStyles();
  return (
    <div>
      <h6>CONTACT INFO</h6>
      <address className={classes.address}>
        <ul>
          <li>
            <a href={`mailto:${appBrands.emailAddress}?subject=We are happy to hear from you`}>
              <MailOutlineIcon className={classes.icon} />
              {appBrands.emailAddress}
            </a>
          </li>
          <li>
            <LocationOnIcon className={classes.icon} />
            {appBrands.companyAddress}
          </li>
          <li>
            <a href={`tel:${appBrands.companyPhoneNumber}`}>
              <CallIcon className={classes.icon} />
              {appBrands.companyPhoneNumber}
            </a>
          </li>
        </ul>
      </address>
    </div>
  );
};

export default ContactInfo;
