import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import { Style } from './Style';
import coniStack from '../../../images/coins-stacks.png';
import Verified from '../../../images/verified-protection.png';
import callCenter from '../../../images/call-center-woman.png';

const useStyles = makeStyles((theme) => Style(theme));

const GettingStarted = () => {
  const classes = useStyles();

  return (
    <article className={classes.root}>
      <Container className={classes.container}>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>1.</div>
                <img alt="about us" src={Verified} className={classes.image} />
                <Typography variant="h6" align="center">
                  Safe Shopping
                </Typography>
                <Typography variant="body1" align="center">
                  Your experience with us is highly secured ,shop with peace of mind
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>2.</div>
                <img alt="about us" src={coniStack} className={classes.image} />
                <Typography variant="h6" align="center">
                  Value for your money
                </Typography>
                <Typography variant="body1" align="center">
                  we make sure you get the value of every penny you spend
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>3.</div>
                <img alt="about us" src={callCenter} className={classes.image} />
                <Typography variant="h6" align="center">
                  24/7 Customer Service
                </Typography>
                <Typography variant="body1" align="center">
                  We are always ready to hear from you.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </article>
  );
};

export default GettingStarted;
