import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Button } from '@material-ui/core/';
import { useDispatch, useSelector } from 'react-redux';
import CartProduct from './CartDrawer';
import { changeCartState } from '../../redux/reducers/Cart';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '0',

  },
  list: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 300
    }
  },
  fullList: {
    width: 'auto'
  },
  btn: {
    margin: theme.spacing(0.8, 0)
  }
}));

export default function SwipeableTemporaryDrawer({ icon }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.cart.cartState);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    dispatch(changeCartState({ [anchor]: open }));
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, true)}
    >
      <CartProduct
        toggleDrawer={toggleDrawer(anchor, false)}
      />
    </div>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <Fragment key={anchor}>
          <Button
            color="inherit"
            aria-label="add to shopping cart"
            onClick={toggleDrawer(anchor, true)}
            startIcon={icon}
            className={classes.btn}
          />
          <SwipeableDrawer
            className={classes.root}
            anchor={anchor}
            color="inherit"
            open={cartState[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </Fragment>
      ))}
    </div>
  );
}
