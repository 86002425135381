import axios from 'axios';
import ENV_KEYS from '../config';

const BASE_URL = {
  COOPERATIVE: `${ENV_KEYS.API_BASE_URL}/v1/cooperative`,
  COOPERATIVE_AUTH: `${ENV_KEYS.API_BASE_URL}/api`,
};

const FoodCrowdyApi = axios.create({
  baseURL: BASE_URL.COOPERATIVE_AUTH,
  responseType: 'json'
});

export {
  BASE_URL,
  FoodCrowdyApi
};
