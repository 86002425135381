import { combineReducers } from '@reduxjs/toolkit';
import cart from './Cart';
import allCooperative from './cooperatives';
import products from './Product';
import platform from './app';
import userAuth from './auth';

const rootReducer = combineReducers({
  cart,
  userAuth,
  allCooperative,
  products,
  platform
});

export default rootReducer;
