import React, { useEffect } from 'react';
import {
  Avatar,
  Container,
  Divider,
  FormControl,
  FormControlLabel, makeStyles,
  Paper, Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import VerifyAccount from '../Mail';
import { Style } from './Style';
import SendOtp from '../SendOtp/SendOtp';
import LoginForm, { LoginFormFooter } from './Form';
import useCoopLogin from '../Hooks/useCoopLogin';
import SignInGA from '../../../GoogleAnalytics/Events/SignIn';
import BackDropLoader from '../../backdrop/Backdrop';
import { useSearchQuery } from '../../../hooks/useSearchQuery';

const useStyles = makeStyles((theme) => Style(theme));

const Login = ({
  location, logo, title, route
}) => {
  const classes = useStyles();
  const auth = useSearchQuery(location).get('verified');
  const {
    isVerified,
    handleChangeForm,
    phoneNumber, setIsVerified,
    handleLoginType,
    formState,
    loading,
    setMessage,
    // setPhoneNumber,
    message, handleLogin, pmethod
  } = useCoopLogin(route);

  useEffect(() => {
    SignInGA();
  }, []);

  return (
    <Container
      className={classes.root}
      component="main"
      maxWidth="xs"
    >
      {isVerified && (
        <VerifyAccount
          setIsVerifed={setIsVerified}
          phoneNumber={phoneNumber}
        />
      )}
      <Paper className={classes.paper}>
        <Avatar
          src={logo}
          className={classes.avatar}
        />
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        {!auth ? (
          <FormControl required component="fieldset">
            <RadioGroup
              aria-label="foodCategory"
              name="category"
              value={pmethod}
              onChange={handleLoginType}
              className={classes.typograghy}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="1. New User"
              />
              <Divider />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="2. Current User"
              />
              <Divider />
            </RadioGroup>
          </FormControl>
        ) : ''}
        {loading && <BackDropLoader load />}
        <form className={classes.form}>
          {message && (
            <Alert
              className={classes.alert}
              variant="outlined"
              severity="error"
            >
              {message}
            </Alert>
          )}
          {
            (pmethod === '2' || auth)
              ? (
                <LoginForm
                  classes={classes}
                  handleChange={handleChangeForm}
                  formState={formState}
                  handleLogin={handleLogin}
                  loading={loading}
                />
              )
              : (
                <SendOtp
                  classes={classes}
                  setIsVerifed={setIsVerified}
                  setMessage={setMessage}
                />
              )
          }
          <LoginFormFooter classes={classes} />
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
