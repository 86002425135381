import React, { forwardRef } from 'react';
import ScrollToTop from '../../routes/ScrollToTop';
import MetaTag from '../../metatags/Tagsheader/TagsHeader';

const Page = forwardRef(({
  children,
  nameContent, title,
  contentImage, propertyTitleContent,
  ...rest
}, ref) => {
  return (
    <div {...rest} ref={ref}>
      <ScrollToTop />
      <MetaTag
        title={title}
        nameContent={nameContent}
        contentImage={contentImage}
        propertyTitleContent={propertyTitleContent}
      />
      {children}
    </div>
  );
});

export default Page;
