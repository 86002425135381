import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  makeStyles, useTheme,
} from '@material-ui/core';
import { Menu as MenuIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Styles } from './Styles';
import UserUtils from '../../../../utils/functions/users/userUtils';

const useStyles = makeStyles(Styles);

const SideNav = ({ loggedInUserNav, loggedOutUserNav, routes }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { user: userInfo } = useSelector((state) => state.userAuth);
  const user = userInfo?.userName;
  const name = userInfo?.name;
  const history = useHistory();
  const [state, setState] = useState({
    right: false
  });

  const { userLogout } = UserUtils;
  const {
    userLogin,
    userProfile
  } = routes;
  const LoginInNav = (
    <>
      {loggedInUserNav.map((text) => (
        <Link
          key={text.id}
          to={text.path}
          style={{ color: theme.palette.secondary.main }}
        >
          <ListItem button key={text}>
            <ListItemIcon className={classes.listIcon}>{text.icon}</ListItemIcon>
            <ListItemText primary={text.name} />
          </ListItem>
          <Divider />
        </Link>
      ))}
    </>
  );
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.close}>
        <ChevronRightIcon />
      </div>
      <div className="profile-icon">
        {(user || name) && (
          <>
            <Avatar
              alt={user || name}
              className={classes.avatar}
              component={Link}
              src="/images/avatars/avatar_11.png"
              to={userProfile}
            />
          </>
        )}
      </div>
      <List>
        {user || name
          ? LoginInNav
          : loggedOutUserNav.map((text) => (
            <Link
              className={classes.anchorTag}
              key={text.id}
              to={text.path}
            >
              <ListItem button key={text}>
                <ListItemIcon
                  className={classes.listIcon}
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText primary={text.name} />
              </ListItem>
              <Divider />
            </Link>
          ))}
        {user || name ? (
          <Button
            onClick={(e) => userLogout(e, history)}
            variant="contained"
            className={classes.btn}
            color="primary"
          >
            <strong>LOGOUT</strong>
          </Button>
        ) : (
          <Link to={userLogin}>
            <Button
              variant="contained"
              className={classes.btn}
              color="primary"
            >
              <strong>LOGIN</strong>
            </Button>
          </Link>
        )}
      </List>
      <Divider />
    </div>
  );

  return (
    <div className="menuicon">
      <Button
        className="menuicon"
        onClick={toggleDrawer('right', true)}
      >
        <MenuIcon className="side-nav-menu" />
      </Button>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer('right', false)}
      >
        {list('right')}
      </Drawer>
    </div>
  );
};

export default SideNav;
