import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from 'react-redux';
import AppBarView from './AppBar/AppBarView';
import DrawerView from './Drawer/DrawerView';
import { dashboardStyles } from './Styles';

const useStyles = makeStyles((theme) => (dashboardStyles(theme)));

const AdminDashBoard = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { user } = useSelector((state) => state.userAuth);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarView
        open={open}
        isLogged={user?.id}
        classes={classes}
        handleDrawerOpen={handleDrawerOpen}
      />
      <DrawerView
        open={open}
        theme={theme}
        user={user?.id}
        classes={classes}
        handleDrawerClose={handleDrawerClose}
      />
      <main
        className={classes.content}
      >
        {children}
      </main>
    </div>
  );
};

export default AdminDashBoard;
