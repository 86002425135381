import React from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { appBrands } from '../../constants/Brands';

const TagsHeader = ({
  title,
  nameContent,
  propertyTitleContent,
  contentImage
}) => {
  return (
    <MetaTags>
      <title>{title}</title>
      <meta name="description" content={nameContent} />
      <meta property="og:title" content={propertyTitleContent} />
      <meta property="og:image" content={contentImage} />
    </MetaTags>
  );
};

TagsHeader.defaultProps = {
  contentImage: appBrands.publicIcon
};

TagsHeader.prototype = {
  title: PropTypes.string.isRequired,
  nameContent: PropTypes.string.isRequired,
  propertyTitleContent: PropTypes.string.isRequired
};

export default TagsHeader;
