import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({ customLogo, homeUrl }) => {
  return (
    <Link to={homeUrl} title="food crowdy">
      <img
        className="logo"
        height="50"
        src={customLogo}
        alt="food crowdy logo"
      />
    </Link>
  );
};

Logo.defaultProps = {
  homeUrl: '/'

};

export default Logo;
