import banner1 from '../../../images/Banner-2.jpg';
import banner2 from '../../../images/Banner-3.jpg';

export const Banners1 = [
  {
    imgSrc: 'https://res.cloudinary.com/accelerar/image/upload/v1601208409/FrontendAssests/Carousels/Easy-delivery_c0okxi.jpg',
    title: 'buy, share and save"',
    link: '##'
  },
  {
    imgSrc: 'https://res.cloudinary.com/accelerar/image/upload/v1601208259/FrontendAssests/Carousels/Deals_egn5d7.jpg',
    title: 'buy, share and save"',
    link: '##'
  },
  {
    imgSrc: 'https://res.cloudinary.com/accelerar/image/upload/v1601208043/FrontendAssests/Carousels/buy-smartweb_fql13j.jpg',
    title: 'buy, share and save"',
    link: '##'
  },
  {
    imgSrc: 'https://res.cloudinary.com/accelerar/image/upload/v1601208037/FrontendAssests/Carousels/Buy-Bulk_hoecde.jpg',
    title: 'buy, share and save',
    link: '##'
  },
  {
    imgSrc: 'https://res.cloudinary.com/accelerar/image/upload/v1601208002/FrontendAssests/Carousels/smart-farmers_j1c4eh.jpg',
    title: 'buy, share and save',
    link: '##'
  },
];

export const Banners2 = [
  {
    imgSrc: banner1,
    title: 'buy, share and save"',
    link: '##'
  },
];
export const Banners3 = [
  {
    imgSrc: banner2,
    title: 'buy, share and save"',
    link: '##'
  },
];
