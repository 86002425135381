import React from 'react';
import Footer from '../../../shared/Footer/Footer';
import { FOOTER_NAV, PARTNERSHIP } from './dataList';

const MainFooter = () => {
  return (
    <Footer
      footerNav={FOOTER_NAV}
      partnership={PARTNERSHIP}
    />
  );
};

export default MainFooter;
