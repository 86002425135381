import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import {
  Box, makeStyles, Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Media } from '../../backdrop/AppShell';
import ProductCard from '../productCard/ProductCard';
import CustomArrows from '../customarrows/CustomArrows';
import productImg from '../../../images/category.svg';
import { EmptyList } from '../../EmptyList/EmptyList';
import { responsive } from '../../../utils/SlidersMediaQuery/MediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 3)
  },
  box: {
    color: theme.palette.white,
    borderTopLeftRadius: '.3rem',
    borderTopRightRadius: '.3rem',
    padding: theme.spacing(1.3, 1),
    background: theme.palette.secondary.main,
  }
}));

const ProductView = ({
  data, isLoading,
  title, type, link
}) => {
  const classes = useStyles();
  if (isLoading) {
    return <Media block={4} />;
  }

  if (!data.length) {
    if (type === 'viewed') {
      return <></>;
    }
    return (
      <EmptyList
        imageFile={productImg}
        text="No Product Found"
        color="secondary"
      />
    );
  }

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        className={classes.box}
        justifyContent="space-between"
      >
        <Typography variant="h6" color="inherit" component="h4">
          {title}
        </Typography>
        <Typography variant="h6" color="inherit" component="h4">
          <Link className="category-list" to={link}>
            See All
          </Link>
        </Typography>
      </Box>
      <Carousel
        arrows={false}
        swipeable
        removeArrowOnDeviceType="mobile"
        customButtonGroup={<CustomArrows />}
        responsive={responsive}
      >
        {data.map((item) => (
          <ProductCard
            key={item._id}
            item={item}
          />
        ))}

      </Carousel>
    </div>
  );
};

ProductView.defaultProps = {
  data: [],
};
ProductView.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ProductView;
