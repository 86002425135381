import React, { useEffect } from 'react';

import { Alert } from '@material-ui/lab/';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/';
import { WatchLater } from '@material-ui/icons';
import { Style } from './Login/Style';
import CustomModal from '../custommodal/CustomModal';
import useCoopLogin from './Hooks/useCoopLogin';

const useStyles = makeStyles((theme) => Style(theme));

const VerifyAccount = ({ verify, phoneNumber, setIsVerifed }) => {
  const classes = useStyles();
  const {
    otp,
    open,
    retry,
    loading,
    message,
    setOpen,
    handleChange,
    sendAccountConfirmationLink,
  } = useCoopLogin();

  useEffect(() => {
    setOpen(true);
  }, [setOpen, verify]);

  return (
    <CustomModal open={open} setOpen={setOpen}>
      <Card className={classes.checkMail}>
        <CardHeader
          title={(
            <Typography variant="h5" align="center">
              Enter your OTP
            </Typography>
          )}
        />
        <Divider />
        <CardContent>
          <span>Enter has been sent to this number</span>
          <br />
          <Typography variant="subtitle2" align="center">
            ********
            <strong>{phoneNumber && phoneNumber.split('').splice(9, 4).join('')}</strong>
          </Typography>
          <form onSubmit={sendAccountConfirmationLink}>
            {message && (
              <Alert variant="outlined" severity="error">
                {message}
              </Alert>
            )}
            <TextField
              fullWidth
              label="Enter Your OTP"
              name="OTP"
              onChange={handleChange}
              style={{ margin: '1rem 0' }}
              type="number"
              value={otp}
              variant="filled"
              required
            />
            <Button
              className={classes.btn}
              color="primary"
              type="submit"
              fullWidth
              disabled={!!loading}
              variant="contained"
            >
              Verify
            </Button>
          </form>
        </CardContent>
        {' '}
        <Button
          className={classes.btn}
          color="secondary"
          fullWidth
          onClick={() => setIsVerifed(false)}
          disabled={!retry}
          variant="text"
        >
          {retry
            ? 'didn\'t receive OTP Resend'
            : (
              <>
                <WatchLater />
                <i>waiting...</i>
              </>
            )}
        </Button>
        <Divider />
      </Card>
    </CustomModal>
  );
};

export default VerifyAccount;
