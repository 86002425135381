import { lazy } from 'react';
import AdminDashBoardLayout from '../../../layouts/AdminDashBoard/AdminDashBoard';
import { dashboardRoutes } from '../../routes.path';

const AdminDashBoardView = lazy(() => import('../../../pages/CooperativeAdmin/Settings/Settings'));
const CooperativeAuthentication = lazy(() => import('../../../pages/Cooperatives/Cooperatives'));
const CooperativeCashToken = lazy(() => import('../../../pages/Auth/Cooperative/Login'));

const {
  AUTH,
  SETTINGS,
  CASH_TOKEN
} = dashboardRoutes;

export const dashBoardPrivateRoutes = [
  {
    path: SETTINGS,
    layout: AdminDashBoardLayout,
    component: AdminDashBoardView,
    authPath: AUTH
  },
];
export const dashBoardPublicRoutes = [
  {
    path: AUTH,
    layout: AdminDashBoardLayout,
    component: CooperativeAuthentication,
  },
  {
    path: CASH_TOKEN,
    layout: AdminDashBoardLayout,
    component: CooperativeCashToken,
  },
];
