import { mainAppPrivateRoutes, mainAppRoutes } from './Main/routes';
import { memCosAppPrivateRoutes, memCosAppRoutes } from './memCos/routes';
import { coopEastAppPrivateRoutes, coopEastAppRoutes } from './coopEast/routes';
import { dashBoardPrivateRoutes, dashBoardPublicRoutes } from './ApiDashboard/routes';

export const publicRoutes = [
  ...coopEastAppRoutes,
  ...memCosAppRoutes,
  ...mainAppRoutes,
  ...dashBoardPublicRoutes
];

export const privateRoutes = [
  ...coopEastAppPrivateRoutes,
  ...memCosAppPrivateRoutes,
  ...mainAppPrivateRoutes,
  ...dashBoardPrivateRoutes
];
