import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import ctimage from '../../images/category.svg';
import fish from '../../images/fish-and-seafood.jpg';
import meats from '../../images/meats.jpg';
import vegetables from '../../images/vegetables.jpg';
import foodstuffs from '../../images/foodstuffs.jpg';

import { responsive } from '../../utils/SlidersMediaQuery/MediaQuery';

const Categories = [
  {
    title: 'vegetables',
    link: '/products/Vegetables',
    image: vegetables
  },
  {
    title: 'meat',
    link: '/products/Meat',
    image: meats
  },
  {
    title: 'foodstuffs',
    link: '/products/Tubers',
    image: foodstuffs
  },
  {
    title: 'seafoods',
    link: '/products/SeaFoods',
    image: fish
  }
];

const Category = () => {
  return (
    <section style={{ margin: '2rem .5rem 0 .5rem' }}>
      <Typography
        variant="h5"
        component="h3"
        color="secondary"
      >
        <img src={ctimage} style={{ margin: '0 .6rem ' }} height="30" alt="categories" />
        <Link to="/products/all">Categories</Link>
      </Typography>
      <Divider />
      <div className="category">
        <Carousel removeArrowOnDeviceType="mobile" swipeable responsive={responsive}>
          {Categories.map((list) => (
            <Link className="category-list" key={list.title} to={list.link}>
              <img src={list.image} alt={list.title} />
            </Link>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Category;
