import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';

import { useDispatch, useSelector } from 'react-redux';
import truckImage from '../../../images/truck.svg';
import $24hrsImage from '../../../images/24-hours.svg';
import CustomModal from '../../custommodal/CustomModal';
import { saveUserState } from '../../../redux/reducers/Product';
import { officeAddress } from '../../../utils/data/location/locations';

const useStyles = makeStyles((theme) => ({
  centerImg: {
    textAlign: 'center',
    '& >*': {
      margin: theme.spacing(2)
    }
  },
  btn: {
    margin: 'auto',
    textAlign: 'center'
  }
}));

export default function ChooseState() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [state, setState] = useState('');
  const { currentState } = useSelector((state) => state.products);
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSaveLocation = (e) => {
    e.preventDefault();
    if (!state) {
      return setMessage('Please select state to continue shopping');
    }
    dispatch(saveUserState(state));
    setOpen(false);
  };

  const handleChange = (e) => {
    setState(e.target.value);
  };

  useEffect(() => {
    if (!currentState) {
      setOpen(true);
    }
  }, [currentState]);

  return (
    <CustomModal
      open={open}
      noBottom
      setOpen={setOpen}
      modalTitle="Select State of Delivery"
    >
      <form onSubmit={onSaveLocation}>
        <div>
          <Typography className={classes.centerImg} variant="body2" gutterBottom>
            <strong>We deliver within 24hrs after payment</strong>
          </Typography>
          <Typography className={classes.centerImg} gutterBottom>
            <img height="50" src={$24hrsImage} alt="24 hours delivery " />
            <img height="50" src={truckImage} alt="24 hours delivery " />
          </Typography>
          {message && <Alert severity="info">{message}</Alert>}
          <FormControl required component="fieldset">
            <RadioGroup
              aria-label="foodCategory"
              name="category"
              onChange={handleChange}
            >
              {officeAddress.map((list) => (
                <FormControlLabel
                  key={list.state}
                  value={list.state}
                  control={<Radio />}
                  label={list.state}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </div>
      </form>
    </CustomModal>
  );
}
