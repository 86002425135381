import React from 'react';
import { makeStyles, CircularProgress, LinearProgress } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200
  },
  root2: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  centerLoad: {
    margin: ' auto',
    background: 'white',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    top: '50%',
    left: '50%',
    zIndex: 99
  }
}));

export default function ProgressLoader({
  height, color, size, centerLoad
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        size={size}
        height={height}
        color={color}
        className={centerLoad && classes.centerLoadenterLoad}
      />
    </div>
  );
}

ProgressLoader.defaultProp = {
  height: 100,
  size: 'small',
  color: 'primary'
};

export const LinearLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress />
      <LinearProgress color="secondary" />
    </div>
  );
};
