/**
 * @roles Admin Permission
 */

export const roles = {
  SUPER_ADMIN: 'super',
  PRODUCT: 'products',
  SALES: 'sales',
  COOP_ADMIN: 'coop_admin',
  CUSTOMER_CARE: 'customer-care',
  LOGISTICS: 'logistics',
  PERMISSION: [
    'admin',
    'user',
    'cooperative'
  ]
};

/** *
 * @localStorage constants used in the app.
 */

 export const USER_AUTH_TOKEN = '__auth_session_token';

 export const COOPERATIVE_STORE = '_cooperative';

 export const PLATFORM_STORE = '__cpt:collection';

 export const CART_STORE = '__shopping:cart';

 export const PRODUCTS_VIEWED = '__products:viewed';

 export const USER_LOCATION = 'user_current_state';

 export const PLATFORM_CONFIG = 'platformConfig';

 /**
  *
  * @requestHeader
  * @BreakingChange
  */
  export const COOKIE_KEY_V1 = 'x-auth-token';

 export const COOKIE_KEY_V2 = 'X-Auth-Token';

/**
 * App Available Status Colors and Delivery Status Object/Array
 */
export const statusColors = {
  delivered: 'success',
  pending: 'warning',
  refunded: 'danger',
  confirmed: 'success',
  suspended: 'danger',
  declined: 'info',
  activated: 'success',
  credit: 'success',
  debit: 'danger',
  successful: 'success',
  failed: 'danger',
  processing: 'warning',
  active: 'success'
};
export const orderDeliveryStatus = [
  'processing',
'set for delivery',
'delivered'
];

/**
 * TODO: Remove Days  and Month Array relace with date picker filePath:
 * src/views/auth/signup
 */

export const days = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31
];

export const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
