export const DLinkStyles = (theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    margin: '2rem auto',
    zIndex: '4',
    padding: '.3rem .5rem ',
    background: theme?.palette.white
  },
  btn: {
  },
  link: {
    color: theme?.palette.black
  },
  popper: {
    zIndex: '3'
  },
  login: {
    margin: '.4rem 0',
    fontSize: '.9rem',
    '&span': {
      fontWeight: '300',
    }
  },
  avatar: {
    height: '10'
  },
  icon: {
    color: theme?.palette.tetiary.main,
    margin: '-.3rem 0'
  }
});

export const DHeaders = (theme) => ({
  icon: {
    margin: '.6rem -.3rem -.3rem  .4rem ',
    color: theme?.palette.tetiary.main
  },
  navIcon: {
    color: theme?.palette.tetiary.main
  },
  icon2: {
    margin: '.-2rem 0rem 0rem  -0rem',
    color: theme?.palette.tetiary.main
  },
  open: {
    display: 'block'
  },
  close: {
    display: 'none'
  },
  search: {
    height: 10
  },
  ul: {
    width: '85%',
    margin: '0 .4rem '
  }
});

export const DTabs = (theme) => ({
  list: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '3.4rem',
    '& li': {
      margin: theme.spacing('auto', 3),
      '& a': {
        color: theme?.palette.white,
      },
      '&:hover': {
        borderBottom: `3px solid ${theme?.palette.primary.main}`,
      }
    }
  }
});
