import React from 'react';
import Footer from '../../../shared/Footer/Footer';
import { FOOTER_NAV } from './dataList';

const MEMFooter = () => {
  return (
    <Footer
      footerNav={FOOTER_NAV}
    />
  );
};

export default MEMFooter;
