import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header/Header';
import Footer from './footer/Footer';
import LayoutWrapper from '../../shared/LayoutWrapper/LayoutWrapper';

const CoopEastLayout = ({ children }) => {
  return (
    <LayoutWrapper
      header={<Header />}
      footer={<Footer />}
    >
      {children}
    </LayoutWrapper>
  );
};

CoopEastLayout.prototype = {
  children: PropTypes.node.isRequired
};

export default CoopEastLayout;
