import ReactGA from 'react-ga';
import { appMode } from '../GoogleAnalytics';

const isProduction = appMode();
const SignIn = () => {
  if (!isProduction) return null;
  return ReactGA.event({
    category: 'User ',
    action: 'Sign in now'
  });
};

export default SignIn;
