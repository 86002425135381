import { lazy } from 'react';
import { memCosRoutes } from '../../routes.path';

import MTNCooperativeLayout from '../../../layouts/MtnCooperative/MtnCooperative';
import CooperativesMemCosAuth from '../../../pages/Cooperatives/memcosAuth/MemcoLogin';

/**
 * MTN Cooperatives
 */
// TODO: Change to MTN cooperative Home page
const MTNCooperativeHome = lazy(() => import('../../../pages/MtnCooperatives/Home'));
const MTNCooperative = lazy(() => import('../../../pages/MtnCooperatives/MtnCoopertaive'));
const ProfileDetails = lazy(() => import('../../../pages/MtnCooperatives/Profile/Profile'));
const MTNCoopContact = lazy(() => import('../../../pages/MtnCooperatives/Contact/Contact'));
const MTNCoopCheckout = lazy(() => import('../../../pages/MtnCooperatives/Checkout/Checkout'));
const MTNCoopHelp = lazy(() => import('../../../pages/MtnCooperatives/HelpCenter/HelpCenter'));
const MTNCoopAdminDashboard = lazy(() => import('../../../pages/MtnCooperatives/Admin/Admin'));
const MTNCooperativeOrders = lazy(() => import('../../../pages/MtnCooperatives/Orders/Orders'));
const MTNProductDetails = lazy(() => import('../../../pages/MtnCooperatives/product/PoductsDetails'));
const MTNCooperativeDashboard = lazy(() => import('../../../pages/MtnCooperatives/Dashboard/DashBoard'));
const MTNCoopPasswordReset = lazy(() => import('../../../pages/MtnCooperatives/ForgotPassword/ResetPassword'));
const MTNCoopForgotPassword = lazy(() => import('../../../pages/MtnCooperatives/ForgotPassword/ForgotPassword'));
const MTNCoopPasswordRecovery = lazy(() => import('../../../pages/MtnCooperatives/Recovery/PasswordReset/PasswordReset'));
const MTNCooperativeSettlement = lazy(() => import('../../../pages/MtnCooperatives/Admin/Settlement/MemcosSettlement'));
const MTNSettlementProducts = lazy(() => import('../../../pages/MtnCooperatives/Admin/Settlement/SettlementsProducts'));
const MTNCoopPaymentVerification = lazy(() => import('../../../pages/MtnCooperatives/PaymentVerification/PaymentVerification'));

const {
  MC_ADMIN_VIEW, MC_CHECKOUT_ORDER, MC_HOME,
  MC_CONTACT, MC_DASHBOARD, MC_FORGOT_PASSWORD,
  MC_HELP_CENTER, MC_LOGIN, MC_ORDERS, MC_PASSWORD_RESET,
  MC_PAYMENT_VERIFICATION, MC_PRODUCT_DETAILS, MC_PRODUCTS,
  MC_PROFILE, MC_REGISTER, MC_SETTLEMENT, MC_SETTLEMENT_DETAILS
} = memCosRoutes;

export const memCosAppRoutes = [
  {
    path: MC_HOME,
    layout: MTNCooperativeLayout,
    component: MTNCooperativeHome
  },
  {
    path: MC_LOGIN,
    layout: MTNCooperativeLayout,
    component: CooperativesMemCosAuth
  },
  {
    path: MC_HELP_CENTER,
    layout: MTNCooperativeLayout,
    component: MTNCoopHelp
  },
  {
    path: MC_CONTACT,
    layout: MTNCooperativeLayout,
    component: MTNCoopContact
  },
  {
    path: MC_FORGOT_PASSWORD,
    layout: MTNCooperativeLayout,
    component: MTNCoopForgotPassword
  },
  {
    path: MC_PASSWORD_RESET,
    layout: MTNCooperativeLayout,
    component: MTNCoopPasswordRecovery
  },
  {
    path: MC_REGISTER,
    layout: MTNCooperativeLayout,
    component: MTNCoopPasswordReset
  },
];

export const memCosAppPrivateRoutes = [
  {
    path: MC_PRODUCTS,
    layout: MTNCooperativeLayout,
    component: MTNCooperative,
    authPath: MC_LOGIN
  },
  {
    path: MC_ADMIN_VIEW,
    layout: MTNCooperativeLayout,
    component: MTNCoopAdminDashboard,
    authPath: MC_LOGIN
  },
  {
    path: MC_PRODUCT_DETAILS,
    layout: MTNCooperativeLayout,
    component: MTNProductDetails,
    authPath: MC_LOGIN
  },
  {
    path: MC_DASHBOARD,
    layout: MTNCooperativeLayout,
    component: MTNCooperativeDashboard,
    authPath: MC_LOGIN
  },
  {
    path: MC_CHECKOUT_ORDER,
    layout: MTNCooperativeLayout,
    component: MTNCoopCheckout,
    authPath: MC_LOGIN
  },
  {
    path: MC_ORDERS,
    layout: MTNCooperativeLayout,
    component: MTNCooperativeOrders,
    authPath: MC_LOGIN
  },
  {
    path: MC_PROFILE,
    layout: MTNCooperativeLayout,
    component: ProfileDetails,
    authPath: MC_LOGIN
  },
  {
    path: MC_PAYMENT_VERIFICATION,
    layout: MTNCooperativeLayout,
    component: MTNCoopPaymentVerification,
    authPath: MC_LOGIN
  },
  {
    path: MC_SETTLEMENT,
    layout: MTNCooperativeLayout,
    component: MTNCooperativeSettlement,
    authPath: MC_LOGIN
  },
  {
    path: MC_SETTLEMENT_DETAILS,
    layout: MTNCooperativeLayout,
    component: MTNSettlementProducts,
    authPath: MC_LOGIN
  },
];
