import React from 'react';
import NavBar from './Mobile/NavBar';
import DesktopNav from './Desktop/Tabs';
import DesktopNavHeader from './Desktop/Headers';
import TopNotifications from './TopNotifications/TopNotifications';

const Header = ({
  customLogo,
  cartDrawer,
  searchBar,
  routes, navList,
  loggedInUserNav,
  loggedOutUserNav
}) => {
  return (
    <header className="header">
      <TopNotifications />
      <DesktopNavHeader
        routes={routes}
        searchBar={searchBar}
        homeUrl={routes?.home}
        CartDrawer={cartDrawer}
        customLogo={customLogo}
        shopUrl={routes?.shopUrl}
      />
      <NavBar
        searchBar={searchBar}
        customLogo={customLogo}
        cartDrawer={cartDrawer}
        routes={routes}
        loggedInUserNav={loggedInUserNav}
        loggedOutUserNav={loggedOutUserNav}
      />
      <DesktopNav
        LinkData={navList}
      />
    </header>
  );
};

export default Header;
