/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Divider, makeStyles, Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import cartImage from '../../../images/commerce.png';
import { EmptyList } from '../../EmptyList/EmptyList';
import MediaControlCard from './Card';
import UserUtils from '../../../utils/functions/users/userUtils';

const useStyles = makeStyles((theme) => ({
  mobileCard: {
    boxShadow: '',
    margin: theme.spacing(0.6),
    borderRadius: '',
  },
  mobileCart: {
    padding: '0.5rem 0',
    margin: '0.8rem 0.6rem',
  },
}));

const CartCards = ({ cartDisplay }) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.userAuth);

  const routeToShop = () => {
    const coopType = UserUtils.userAuthType(user?.coopId);
    switch (coopType) {
      case 'COOPEAST':
        return '/coopeast/products';
      case 'MEMCOS':
        return '/mtn-memcos-cooperative/all';
      default:
        return '/products/all';
    }
  };

  return (
    <div className={classes.mobileCart}>
      <Typography
        variant="h5"
        color="textSecondary"
        component="h4"
      >
        My Cart(
        {cartDisplay.length}
        )
      </Typography>
      <Divider />
      {!cartDisplay.length ? (
        <EmptyList
          imageFile={cartImage}
          text="Start Shopping"
          link={routeToShop()}
          color="primary"
          variant="contained"
          height="200"
        />
      ) : (
        <MediaControlCard />
      )}
    </div>
  );
};

export default CartCards;
