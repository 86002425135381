import {
  Divider, IconButton, makeStyles, Typography
} from '@material-ui/core';
import { AddOutlined, Delete, Remove } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { formatter } from '../../../utils/formatters/formatter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
  },
  button: {
    borderRadius: '.4rem',
    margin: theme.spacing(0, 0.5),
    background: theme.palette.background.default,
    '& span': {
      fontSize: theme.typography.pxToRem('17')
    }
  },
  pTag: {
    textAlign: 'left',
    '& > * ': {
      margin: theme.spacing(0, 0.4)
    }
  },
}));

const SubCart = ({
  incrementCartItem,
  quantity,
  decrementCartItem,
  item, list,
  disableDelete,
  deleteCartVariant
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Divider />
      {!disableDelete && (
        <div>
          <IconButton
            size="small"
            color="secondary"
            className={classes.button}
            aria-label="minimize"
            onClick={() => dispatch(deleteCartVariant({
              variantId: item._id,
              productId: list.productId
            }))}
          >
            <Delete
              fontSize="small"
            />
          </IconButton>
        </div>
      )}
      <Typography variant="subtitle1" className={classes.pTag}>
        <Typography variant="caption">
          {' '}
          {item?.unit}
        </Typography>
        <Typography variant="caption">
          {' '}
          {formatter.format(item?.price)}
        </Typography>
      </Typography>
      <div className={classes.button}>
        <IconButton
          size="small"
          color="secondary"
          className={classes.button}
          aria-label="minimize"
          onClick={() => dispatch(decrementCartItem(item._id))}
        >
          <Remove
            fontSize="small"
          />
        </IconButton>
        <span>
          {quantity}
        </span>
        <IconButton
          size="small"
          color="secondary"
          aria-label="add"
          onClick={() => dispatch(incrementCartItem(item._id))}
        >
          <AddOutlined fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default SubCart;
