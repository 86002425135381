import React from 'react';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  PersonOutline as PersonOutlineIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon
} from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';

const LinksMenu = ({
  open, anchorRef, classes,
  handleClose, name, userName,
  handleListKeyDown, handleLogout, routes
}) => {
  const history = useHistory();

  const {
    userLogin,
    userProfile,
    userOrders
  } = routes;
  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      className={classes.popper}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom'
              ? 'center top' : 'center bottom',
            background: '#fff'
          }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={handleClose}>
                  {userName || name ? (
                    <Button
                      className={classes.btn}
                      type="submit"
                      variant="contained"
                      fullWidth
                      onClick={(e) => handleLogout(e, history)}
                      color="primary"
                    >
                      <span>LOGOUT</span>
                    </Button>
                  ) : (
                    <Button
                      className={classes.btn}
                      type="submit"
                      title="login"
                      variant="contained"
                      fullWidth
                      href={userLogin}
                      color="primary"
                    >
                      <span>LOGIN</span>
                    </Button>
                  )}
                </MenuItem>
                <MenuItem>OR</MenuItem>
                <MenuItem>
                  {userName || name ? (
                    <Link className={classes.link} to={userProfile}>
                      <PersonOutlineIcon className={classes.icon} />
                      {' '}
                      My profile
                    </Link>
                  ) : (
                    <Link className={classes.link} to={userLogin}>
                      Create an Account
                    </Link>
                  )}
                </MenuItem>
                <MenuItem>
                  {(userName || name) && (
                    <Link
                      className={classes.link}
                      to={userOrders}
                    >
                      <AccountBalanceWalletIcon
                        className={classes.icon}
                      />
                      {' '}
                      My Orders
                    </Link>
                  )}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default LinksMenu;
