import { toast } from 'react-toastify';

// const statusCode = [401, 400, 413, 422, 403];
export const handleError = (error) => {
  try {
    if (error) {
      if (error.response === undefined) {
        return {
          message: 'Network not available',
          status: undefined
        };
      }
      if (error.response.status === 500) {
        toast.error('Something went wrong please refresh ', {
          toastId: 'Internal Server Error',
          position: toast.POSITION.BOTTOM_RIGHT
        });
        return {
          message: 'Internal Server Error',
          status: error.response.status
        };
      }
      if (error.response.status === 404) {
        return {
          message: 'Page not found',
          status: error.response.status
        };
      }
      return {
        message: error?.response?.data?.message,
        status: error.response.status,
        type: error.response.data.type
      };
    }
  } catch (error) {
    return {
      message: 'An error occurred please try again ',
      status: ''
    };
  }
};
