import React from 'react';
import { Badge, makeStyles } from '@material-ui/core';
import {
  ShoppingCartOutlined as ShoppingCartIcon,
} from '@material-ui/icons/';
import { useSelector } from 'react-redux';
import Header from '../../../shared/Header/Header';
import CartDrawer from '../../../components/cart/Cart';
import {
  activeMenu,
  LOGGEDIN_USER_NAV,
  LOGGEDOUT_USER_NAV,
  navLinksData, userRoutes
} from './dataList';
import CoopSearchBar from '../../../components/searchBar/SearchBar';

import { CartStyles } from '../../../shared/Header/Mobile/sidenav/Styles';
import { appBrands } from '../../../constants/Brands';

const useStyles = makeStyles((theme) => CartStyles(theme));

const Carts = ({ cartItem }) => {
  const classes = useStyles();
  return (
    <CartDrawer
      icon={(
        <Badge badgeContent={cartItem.length} color="secondary">
          <ShoppingCartIcon className={classes.icon2} />
        </Badge>
      )}
    />
  );
};

const MainHeader = () => {
  const { products } = useSelector((state) => state.products);
  const cartItem = useSelector((state) => state.cart?.cart);

  const {
    cooperative: {
      mtnMemCos: { logoUrl }
    }
  } = appBrands;

  /*
 * Add Tab bar theme props quick fix
 * className appbar-mtn
 */
  return (
    <Header
      theme={{ color: '#ffcc2a' }}
      active={activeMenu}
      routes={userRoutes}
      navList={navLinksData}
      searchBar={(
        <CoopSearchBar
          urls={{
            details: '/mtn-memcos-cooperative',
            all: '/mtn-memcos-cooperative/all'
          }}
          data={products}
        />
      )}
      customLogo={logoUrl}
      loggedInUserNav={LOGGEDIN_USER_NAV}
      loggedOutUserNav={LOGGEDOUT_USER_NAV}
      cartDrawer={<Carts cartItem={cartItem} />}
    />
  );
};

export default MainHeader;
