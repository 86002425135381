import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Store from './redux/store';

import App from './App';

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>, document.getElementById('root')
);
serviceWorker.unregister();
