import { Checkbox, IconButton, makeStyles } from '@material-ui/core';
import { AddOutlined, Remove } from '@material-ui/icons';
import React from 'react';
import { formatter } from '../../../utils/formatters/formatter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    '& > * ': {
      margin: theme.spacing(0, 1)
    }
  },
  button: {
    borderRadius: '.4rem',
    margin: theme.spacing(0, 0.5),
    background: theme.palette.background.default,
    '& span': {
      fontSize: theme.typography.pxToRem('18'),
      fontWeight: theme.typography.fontWeightBold,
    }
  },
  pTag: {
    textAlign: 'left',
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem('18'),
    '& > * ': {
      margin: theme.spacing(0, 0.4)
    }
  },
}));

const Variants = ({
  handleVariant,
  incrementQty,
  quantity,
  decrementQty, labelId, item
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Checkbox
          onClick={(e) => handleVariant(e, item)}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </div>
      <p className={classes.pTag}>
        <span>
          {' '}
          {item?.unit}
        </span>
        <span>
          {' '}
          {formatter.format(item?.price * quantity)}
        </span>
      </p>
      <div className={classes.button}>
        <IconButton
          size="small"
          color="secondary"
          aria-label="minimize"
          onClick={() => decrementQty(item)}
        >
          <Remove
            fontSize="small"
          />
        </IconButton>
        <span>
          {' '}
          {quantity}
        </span>
        <IconButton
          size="small"
          color="secondary"
          aria-label="add"
          onClick={() => incrementQty(item)}
        >
          <AddOutlined fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default Variants;
