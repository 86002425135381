import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { CART_STORE } from '../../../utils/data/constants/constants';
import _ from '../../../utils/functions/app/AppUtils';

const cart = _.getLocalStoreItem({
 name: CART_STORE,
   type: []
 });

const initialState = {
  cart,
  variants: [],
  cartState: {
    right: false,
  }
};

const shoppingCart = createSlice({
  name: 'Cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const item = action.payload;
      if (!state.variants.length) {
        toast.warning(
          `
        Please Select Variant , 
        Click on the check box to select variant 
        `, { toastId: 'cart', }
        );
        return state;
      }
      const getTotal = () => {
        const data = state.variants.map((list) => ({
          ...list,
          total: list.requestQty * list.price
        })).reduce((a, b) => (a + b.total), 0);
        return data;
      };
      const totalPrice = getTotal();

      const data = {
        productId: item._id,
        image: item.image,
        title: item.title,
        category: item.category,
        productState: item.state,
        cooperativeId: item.cooperativeId,
        unitSpace: 3,
        variants: state.variants,
        totalPrice,
      };
      const isAvailable = state.cart.find((cart) => (cart.productId === item._id));
      if (isAvailable) {
        const addedItem = state.cart.map((cart) => (
          cart.productId === item._id
            ? data
            : cart
        ));
        state.cart = addedItem;
        _.saveLocalStoreItem({ name: CART_STORE, data: state.cart });
      } else {
        state.cart.push(data);
       _.saveLocalStoreItem({ name: CART_STORE, data: state.cart });
      }
      toast.success('Your cartItem has been added to cart', {
        toastId: 'cart',
        position: toast.POSITION.BOTTOM_RIGHT
      });
      state.cartState.right = true;
      state.variants = [];
      return state;
    },

    incrementCartItem(state, action) {
      const id = action.payload;
      const cartItem = state.cart.map((item) => {
        return item.variants.map((item) => (item._id === id
          ? {
            ...item,
            requestQty: item.requestQty === item.quantity
              ? item.requestQty : item.requestQty++,
          }
          : item));
      });

    _.saveLocalStoreItem({ name: CART_STORE, data: cartItem });
      return state;
    },
    decrementCartItem(state, action) {
      const id = action.payload;
      const cartItem = state.cart.map((item) => {
        return item.variants.map((item) => (item._id === id
          ? {
            ...item,
            requestQty: item.requestQty === 1
              ? item.requestQty : item.requestQty--,
          }
          : item));
      });

    _.saveLocalStoreItem({ name: CART_STORE, data: cartItem });
      return state;
    },
    removeCartItem(state, action) {
      const id = action.payload;
      const remainingCartItems = state.cart.filter((item) => item.productId !== id);
      state.cart = remainingCartItems;
     _.saveLocalStoreItem({ name: CART_STORE, data: state.cart });
      return state;
    },
    clearAllCartItem(state, /* action */) {
      state.cart = [];
     _.saveLocalStoreItem({ name: CART_STORE, data: state.cart });
      return state;
    },
    changeCartState(state, action) {
      const value = action.payload;
      state.cartState.right = value.right;
      return state;
    },
    addVariant(state, action) {
      const { _id } = action.payload;
      const cartItems = (state.variants || []).filter((item) => item._id === _id);
      if (cartItems.length) {
        state.variants.map((item) => (item._id === _id
          ? {
            ...item,
            requestQty: item.requestQty
          }
          : item));
      } else {
        state.variants.push(action.payload);
      }
      return state;
    },
    incrementVariantQty(state, action) {
      const id = action.payload;
      const cartItems = (state.variants || []).filter((item) => item._id === id);
      if (!cartItems.length) {
        toast.warning(`
        Please Select Variant , 
        Click on the check box to select variant 
        `, { toastId: 'cart', });
        return state;
      }
      state.variants.map((item) => (item._id === id
        ? {
          ...item,
          requestQty: item.requestQty === item.quantity
            ? item.requestQty : item.requestQty++,
        }
        : item));
      return state;
    },
    decrementVariantQty(state, action) {
      const id = action.payload;
      state.variants.map((item) => (item._id === id
        ? {
          ...item,
          requestQty: item.requestQty === 1
            ? item.requestQty : item.requestQty--
        }
        : item));
      return state;
    },
    removeVariant(state, action) {
      const id = action.payload;
      const remainingCartItems = state.variants.filter((item) => item._id !== id);
      state.variants = remainingCartItems;
      return state;
    },
    deleteCartVariant(state, action) {
      const { variantId, productId } = action.payload;
      const cartItem = state.cart.map((item) => {
        if (item.variants.length === 1) {
          const remainingCartItems = state.cart.filter((item) => item.productId !== productId);
          return remainingCartItems;
        }
        return { ...item, variants: item.variants.filter((item) => (item._id !== variantId)) };
      }).flat();
      state.cart = cartItem;
     _.saveLocalStoreItem({ name: CART_STORE, data: state.cart });
      return state;
    },
    clearVariant(state, /* action */) {
      state.variants = [];
      return state;
    }
  }
});

export const {
  addToCart,
  addVariant,
  clearVariant,
  removeVariant,
  removeCartItem,
  clearAllCartItem,
  changeCartState,
  deleteCartVariant,
  decrementCartItem,
  incrementCartItem,
  decrementVariantQty,
  incrementVariantQty,
} = shoppingCart.actions;

export default shoppingCart.reducer;
