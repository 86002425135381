import React from 'react';
import {
  LiveHelp as LiveHelpIcon,
  Phone as PhoneIphoneIcon,
  MailOutline as MailOutlineIcon
} from '@material-ui/icons/';
import { appBrands } from '../../../constants/Brands';

const TopNotifications = () => {
  return (
    <div className="top-message">
      <span>
        <LiveHelpIcon className="contacticons" color="inherit" />
        {' '}
        Online support 24/7
      </span>
      <div className="contactLinks">
        <span>
          <a href={`mailto:${appBrands.emailAddress}?subject=We are happy to hear from you`}>
            <MailOutlineIcon className="contacticons" color="inherit" />
            {appBrands.emailAddress}
          </a>
        </span>
        |
        <span>
          <a
            href={`tel: ${appBrands.companyPhoneNumber}`}
          >
            <PhoneIphoneIcon
              className="contacticons"
              color="inherit"
            />
            Call
            {appBrands.companyPhoneNumber}
          </a>
        </span>
      </div>
    </div>
  );
};

export default TopNotifications;
