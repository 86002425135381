import { createSlice } from '@reduxjs/toolkit';
import { handleError } from '../../../errors/ErrorMgs';
import { FoodCrowdyApi } from '../../../services/config';
import { PRODUCTS_VIEWED, USER_LOCATION } from '../../../utils/data/constants/constants';
import _ from '../../../utils/functions/app/AppUtils';

const viewedProducts = _.getLocalStoreItem({
 name: PRODUCTS_VIEWED,
   type: []
});
const currentState = _.getLocalStoreItem({ name: USER_LOCATION });

const initialState = {
  currentState,
  products: [],
  viewedProducts,
  loading: false,
  categories: [],
  searchedResult: [],
  currentCategory: 'all',
};

const Products = createSlice({
  initialState,
  name: 'Products',
  reducers: {
    saveRecentlyViewedProducts(state, action) {
      const { _id } = action.payload;
      if (state.viewedProducts.length === 10) {
        const removedLastOne = state.viewedProducts.shift();
        _.saveLocalStoreItem({ name: PRODUCTS_VIEWED, data: removedLastOne });
        return state;
      }
      const cartItems = state.viewedProducts.filter((item) => item._id === _id);
      if (!cartItems.length) {
        state.viewedProducts.push(action.payload);
        _.saveLocalStoreItem({
 name: PRODUCTS_VIEWED,
          data: state.viewedProducts
});
      } else {
        return state;
      }
      return state;
    },
    getAllCategories(state, action) {
      const data = action.payload;
      state.categories = data;
      state.loading = !state.loading;
      return state;
    },
    getProducts(state, action) {
      const { data } = action.payload;
      state.products = [];
      state.products = data?.docs;
      state.loading = false;
      return state;
    },
    searchByCategory(state, action) {
      const data = action.payload;
      state.products = data?.docs;
      state.loading = false;
      return state;
    },
    setCategory(state, action) {
      const data = action.payload;
      state.currentCategory = data;
      return state;
    },
    getFilteredState(state, action) {
      const { currentState } = action.payload;
      const productList = state.products.filter((item) => (
         item.state === currentState
         ));
      state.searchedResult = productList;
      return state;
    },
    saveUserState(state, action) {
      const data = action.payload;
      state.currentState = data;
    _.saveLocalStoreItem({ name: USER_LOCATION, data });
      return state;
    },
    setLoader(state) {
      state.loading = !state.loading;
      return state;
    }
  }
});

export function getProductCategories() {
  return async function getActualData(dispatch) {
    const { getAllCategories } = Products.actions;
    try {
      const res = await FoodCrowdyApi.get('/products/categories');
      dispatch(getAllCategories(res.data.data));
    } catch (error) {
      handleError(error);
    }
  };
}

export const {
  setLoader,
  setCategory,
  getProducts,
  saveUserState,
  getFilteredState,
  getAllCategories,
  saveRecentlyViewedProducts
} = Products.actions;

export default Products.reducer;
