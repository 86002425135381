export const layoutStyle = (theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
  },
  wrapper: {
    flex: 1,
    minHeight: '100vh',
    background: theme.palette.background.default,
  },
});
