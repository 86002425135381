import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Avatar, Fab } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '@material-ui/icons';
import {
  decrementCartItem,
  incrementCartItem,
  removeCartItem,
  deleteCartVariant
} from '../../../redux/reducers/Cart';
import SubCart from './subCart';
import { formatter } from '../../../utils/formatters/formatter';
import ProductUtils from '../../../utils/functions/products/productUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 280,
    width: 280,
    margin: theme.spacing(0.5, 'auto'),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: theme.spacing(0.5, 0, 0.8, 0),
  },
  content: {
    display: 'flex',
    '& >* ': {
      margin: theme.spacing(0, 0.5),
    }
  },
  btnIcon: {
    margin: theme.spacing(-1, 0, 0, 0),
  }
}));

const _func = ProductUtils;

export default function MediaControlCard() {
  const classes = useStyles();
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  return (
    <>
      {cart.map((list) => (
        <Card className={classes.root} key={Math.random()}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Avatar
                variant="square"
                src={list.image[0]}
              />
              <Typography variant="subtitle1" color="textSecondary">
                {list.title}
              </Typography>
            </CardContent>
          </div>
          <div className={classes.block}>
            <Fab
              size="small"
              color="inherit"
              aria-label="add"
              variant="extended"
              className={classes.btnIcon}
              onClick={() => {
                dispatch(removeCartItem(list.productId));
              }}
            >
              <Delete
                color="error"
                fontSize="small"
              />
              Remove
            </Fab>
            <Typography className={classes.heading}>
              {formatter.format(_func.calcVariantsTotal(list.variants))}
            </Typography>
          </div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Modify Cart</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {list.variants.map((item) => (
                <SubCart
                  list={list}
                  item={item}
                  key={item._id}
                  quantity={item.requestQty}
                  deleteCartVariant={deleteCartVariant}
                  decrementCartItem={decrementCartItem}
                  incrementCartItem={incrementCartItem}
                  disableDelete={list.variants.length === 1}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </Card>
      ))}
    </>
  );
}
