import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Grid } from '@material-ui/core/';
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon
} from '@material-ui/icons/';

import ContactInfo from './ContactInfo';
import { mainRoutes } from '../../routes/routes.path';

const useStyles = makeStyles((theme) => ({
  root: {
    // overflowX: 'hidden',
    margin: theme.spacing(2, 0),
    '& h6 , ul , a': {
      color: theme.palette.white
    },
    '& h6': {
      fontSize: '1rem'
    },
    '& a': {
      fontSize: '.7rem',
      '&:hover': {
        color: theme.palette.primary.main,
        transform: 'scale(0.4)',
        textDecoration: 'underline',
      }
    }
  },
  footer: {
    overflow: 'hidden',
    background: theme.palette.secondary.main,
  },
  list: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '.7rem',
    '& a': {
      textDecoration: 'underline'
    }
  },
  bottom: {
    fontSize: '.7rem',
    textAlign: 'center',
    background: theme.palette.greyColor,
    color: theme.palette.white,
    width: 'auto',
    overflowX: 'hidden'
  }
}));

const { RETURN_POLICY, TERMS_AND_CONDITION, PRIVACY_POLICY } = mainRoutes;

const Footer = ({ footerNav, partnership }) => {
  const classes = useStyles();
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <footer className={classes.footer}>
      <Grid className={classes.root} container spacing={4}>
        <Grid item lg={3} md={6} xl={3} xs={6}>
          <h6>COMPANY</h6>
          <ul className={classes.ul}>
            {footerNav.map((item) => (
              <li key={item.id}>
                <Link to={item.path}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid item lg={3} md={6} xl={3} xs={6}>
          <h6>MAKE MONEY WITH US</h6>
          <ul className={classes.ul}>
            {partnership.map((item) => (
              <li key={item.id}>
                <Link to={item.path}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid item lg={3} md={6} xl={3} xs={10}>
          <ContactInfo />
        </Grid>
        <Grid item lg={3} md={6} xl={3} xs={10}>
          <h6>FOLLOW US ON</h6>
          <a target="_blanck" href="https://www.facebook.com/foodcrowdy/">
            <FacebookIcon />
          </a>
          <a target="_blanck" href="https://twitter.com/foodcrowdy?s=09">
            <TwitterIcon />
          </a>
          <a target="_blanck" href="https://www.instagram.com/foodcrowdy/">
            <InstagramIcon />
          </a>
        </Grid>
      </Grid>
      <Grid className={classes.bottom} container spacing={2}>
        <Grid item lg={6} md={6} xl={3} xs={12}>
          <p>
            {' '}
            FoodCrowdy &copy;
            {' '}
            {currentYear}
            {' '}
            || All Right Reserved
          </p>
        </Grid>
        <Grid item lg={6} md={6} xl={3} xs={12}>
          <ul className={classes.list}>
            <li>
              <Link to={TERMS_AND_CONDITION}>Terms and Conditions |</Link>
            </li>
            <li>
              <Link to={PRIVACY_POLICY}>Privacy Policy |</Link>
            </li>
            <li>
              <Link to={RETURN_POLICY}>Return Policy</Link>
            </li>
          </ul>
        </Grid>
      </Grid>
    </footer>
  );
};

Footer.defaultProps = {
  partnership: [],
  footerNav: []
};

export default Footer;
