const ENV_KEYS = {
  MTN_COOP_ID: process.env.REACT_APP_MTN_COOP,
  GOOGLE_MAP: process.env.REACT_APP_GOOGLE_MAP,
  FB_CRAWLER: process.env.REACT_APP_FB_CRAWLER,
  COOP_EAST_ID: process.env.REACT_APP_COOP_EAST,
  FLUTTER_KEY: process.env.REACT_APP_FLUTTER_KEY,
  BLOG_CLIENT: process.env.REACT_APP_BLOG_CLIENT,
  HOST_CLIENT: process.env.REACT_APP_HOST_CLIENT,
  COOPEAST_SID: process.env.REACT_APP_COOPEAST_SID,
  GOOGLE_LOGIN: process.env.REACT_APP_GOOGLE_LOGIN,
  FACEBOOK_LOGIN: process.env.REACT_APP_FACEBOOK_LOGIN,
  SUBSCRIBE_FRAME: process.env.REACT_APP_SUBSCRIBE_FRAME,
  COOPEAST_OTP_URL: process.env.REACT_APP_COOPEAST_OTP_URL,
  API_BASE_URL: process.env.REACT_APP_BASE_URL_COOPERATIVE,
  GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS,
  COOPEAST_AUTH_URL: process.env.REACT_APP_COOPEAST_AUTH_URL,
  COOPEAST_SCODE_API: process.env.REACT_APP_COOPEAST_SCODE_API,
  FLUTTER_PAYMENT_URL: process.env.REACT_APP_FLUTTER_PAYMENT_URL,
  COOPERATIVE_API_KEY: process.env.REACT_APP_COOPERATIVE_API_KEY,
  FLUTTER_REDIRECT_URL: process.env.REACT_APP_FLUTTER_REDIRECT_URL,
  FLUTTER_REDIRECT_URL_MTN_COOP: process.env.REACT_APP_FLUTTER_REDIRECT_URL_MTN_COOP,
};
export default ENV_KEYS;
