import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0.3),
    right: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes,
    onClose, ...other
  } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function CustomModal({
  children, open, modalTitle, ButtonProps,
  setOpen, outBoxClose, body, addCloseIcon,
  actionButton, noBottom, bottomChild
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      {actionButton}
      <Dialog
        open={open}
        onClose={outBoxClose && handleClose}
        scroll={body ? 'body' : 'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          onClose={addCloseIcon && handleClose}
        >
          {modalTitle}
        </DialogTitle>
        <DialogContent dividers>
          {children}
        </DialogContent>
        {!noBottom && (
          <DialogActions>
            <Button
              {...ButtonProps}
              onClick={handleClose}
            >
              Cancel
            </Button>
            {bottomChild}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

CustomModal.defaultProps = {
  ButtonProps: {
    variant: 'contained',
    color: 'secondary'
  }
};
