export const mainRoutes = {
  CONTACT: '/contact-us',
  PRIVACY_POLICY: '/privacy-policy',
  COOP_LOGIN: '/co-operatives/login',
  TERMS_AND_CONDITION: '/terms-and-conditions',
  RETURN_POLICY: '/return-policy',
  ABOUT: '/about-us',
  HELP_CENTER: '/help/FAQS',
  USER_LOGIN: '/customer/account/login',
  USER_REGISTER: '/customer/account/register',
  SWAGS: '/customer/account/swags-points',
  CHECKOUT_ORDERS: '/checkout-cart',
  PRODUCTS: '/products/:category',
  PAYMENT_VERIFICATION: '/payment-vefication/',
  USER_REFERRALS: '/customer/account/referrals-points',
  PRODUCT_DETAILS_VIEW: '/product-details/:category/:name/:id',
  JOIN_SHARE_VIEW: '/Join-share/:category/:name/:id',
  CLAIM_COUPON_CODE: '/claim-coupon-code',
  USER_PROFILE: '/customer/account/profile',
  USER_LOGISTICS: '/user/logistics',
  USER_ORDERS: '/customer/account/my-orders',
  NEW_PASSWORD: '/customer/forgot-password',
  RESET_PASSWORD: '/users/reset-password',
  VERIFY_EMAIL: '/users/verify-email',
  USER_VIEW: '/customer/account/',
  USER_HOME: '/',
  MC_LOGIN: '/cooperative/login/memcos',
  CE_LOGIN: '/cooperative/login/coopeast',
  SWAG_SHOP: '/swag-store',
  WALLET: '/customer/account/wallet',
};

export const memCosRoutes = {
  MC_HOME: '/##',
  MC_ORDERS: '/mtn-memcos/orders',
  MC_ADMIN_VIEW: '/admin-dashboard',
  MC_HELP_CENTER: '/mtn-memcos/help',
  MC_CONTACT: '/mtn-memcos/contact-us',
  MC_DASHBOARD: '/mtn-memcos/dashboard',
  MC_CHECKOUT_ORDER: '/checkout/memcos',
  MC_LOGIN: '/cooperative/login/memcos',
  MC_SETTLEMENT: '/mtn-memcos/settlements',
  MC_REGISTER: '/cooperatives/registration',
  MC_PROFILE: '/mtn-memcos/account-profile',
  MC_PAYMENT_VERIFICATION: '/mtn-memcos/payments',
  MC_PRODUCTS: '/mtn-memcos-cooperative/:category',
  MC_PASSWORD_RESET: '/cooperatives/password-reset',
  MC_FORGOT_PASSWORD: '/cooperatives/forgot-password',
  MC_SETTLEMENT_DETAILS: '/memcos/settlements/products',
  MC_PRODUCT_DETAILS: '/mtn-memcos-cooperative/:category/:name/:id',
};

export const coopEastRoutes = {
  CE_ORDERS: '/coopeast/orders',
  CE_ABOUT: '/coopeast/about-us',
  CE_PROFILE: '/coopeast/profile',
  CE_HELP_CENTER: '/coopeast/FAQs',
  CE_CHECKOUT: '/checkout/coopeast',
  CE_CONTACT: '/coopeast/contact-us',
  CE_LOGIN: '/cooperative/login/coopeast',
  CE_ALL_PRODUCTS: '/coopeast/products/:category',
  CE_DASHBOARD: '/my-cooperative-account/overview',
  CE_PRODUCTS_DETAILS: '/coopeast/products/:category/:name/:id',
  CE_PAYMENT_VERIFICATION: '/order/cooperative-payment-vefication/',
};

export const dashboardRoutes = {
  HOME: '/overview/',
  SETTINGS: '/dashboard',
  AUTH: '/auth/login',
  CASH_TOKEN: '/cooperative/login/ct'
  // CashToken: '/dashboard',
};

export default {
  ...mainRoutes,
  ...memCosRoutes,
  ...coopEastRoutes,
  ...dashboardRoutes
};
