import { makeStyles } from '@material-ui/core';
import React from 'react';
import ReactWhatsApp from 'react-whatsapp';
import whatsAppImg from '../../images/whatsapp.svg';

const useStyles = makeStyles((theme) => ({
  whatsapp: {
    background: 'transparent',
    border: 'none',
    position: 'fixed',
    bottom: '4%',
    right: '0',
    zIndex: ' 3',
    margin: '2rem',
    outline: 'hidden',
    cursor: 'pointer',
    '&:focus ': {
      outline: 'none',
    },
    '&:hover ': {
      display: 'inline',
    }
  },
  mgs: {
    display: 'none',
    background: theme.palette.white,
    height: '2rem',
    width: '10rem',
    padding: theme.spacing(1),
    borderRadius: '0.5rem',
    border: ` 4px solid ${theme.palette.success.main}`,
    margin: theme.spacing(-1, 3)
  }
}));

const WhatsAppChat = () => {
  const classes = useStyles();
  return (
    <ReactWhatsApp
      className={classes.whatsapp}
      number="+234 81-170-01514"
      message="Hi, we are happy to hear from you!"
    >
      <span className={classes.mgs}>Send us message here</span>
      <img src={whatsAppImg} height="50" alt="chat app" />
    </ReactWhatsApp>
  );
};

export default WhatsAppChat;
