import React from 'react';
import { Button } from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import useCoopLogin from '../Hooks/useCoopLogin';
import PhoneNumberInput from '../../phoneNumberInput/PhoneNumberInput';

const SendOtp = ({
  classes,
  // phoneNumber,
  // setPhoneNumber
}) => {
  const {
    loading, message,
    userVerification,
    phoneNumber, setPhoneNumber
  } = useCoopLogin();

  return (
    <div>
      {message && (
        <Alert
          className={classes.alert}
          variant="outlined"
          severity="error"
        >
          {message}
        </Alert>
      )}
      <PhoneNumberInput
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
      <Button
        className={classes.textField}
        color="primary"
        disabled={!!loading}
        fullWidth
        type="submit"
        size="large"
        onClick={userVerification}
        variant="contained"
      >
        Continue
      </Button>
    </div>
  );
};

export default SendOtp;
