class AppUtils {
  static saveLocalStoreItem({ name, data }) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  static getLocalStoreItem = ({ name, type }) => {
    const items = localStorage.getItem(name);
    if (items) {
      try {
        return JSON.parse(items);
      } catch (error) {
        return type || null;
      }
    } else {
      return type;
    }
  };

  static validateSubdomain() {
    const { origin } = window.location;
    const subdomain = origin.split('.');
    if (subdomain.length === 3 && subdomain[0] !== 'www') {
      return subdomain[0];
    }
    return null;
  }
}

export default AppUtils;
