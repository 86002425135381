import React from 'react';
import Button from '@material-ui/core/Button';
import error from '../../images/error.svg';

const Error404 = () => {
  return (
    <div className="error">
      <div>
        <img src={error} alt="page not found " />
      </div>
      <div>
        <h1 data-testid="Error">Page not found</h1>
        <p>This is not the web page you are looking for </p>
        <p>
          <Button href="/" variant="contained" color="primary">
            GO To Home Page
          </Button>
        </p>
      </div>
    </div>
  );
};

export default Error404;
