import React from 'react';
import {
  Home,
  ContactPhone,
  ShoppingBasketOutlined,
  ShopOutlined,
  PersonOutline,
  Help,
  People,
  Share,
  Business
} from '@material-ui/icons';
import { coopDashboard } from '../../../shared/Header/Desktop/Tabs';
import { mainRoutes } from '../../../routes/routes.path';

const {
  USER_PROFILE,
  USER_LOGIN,
  USER_ORDERS,
  ABOUT, CONTACT,
  HELP_CENTER, USER_HOME,
} = mainRoutes;

const LOGGEDOUT_USER_NAV = [
  {
    id: 1,
    name: 'Home',
    path: '/',
    icon: <Home />
  },
  {
    id: 2,
    name: 'About',
    path: '/about-us',
    icon: <People />
  },
  {
    id: 3,
    name: 'Shop Now',
    path: '/products/all',
    icon: <ShoppingBasketOutlined />
  },
  {
    id: 38,
    name: 'Swag Shopping',
    path: '/swag-store',
    icon: <ShopOutlined />
  },
  {
    id: 70,
    name: 'Contact',
    path: '/contact-us',
    icon: <ContactPhone />
  },
  {
    id: 7,
    name: 'Help Center',
    path: '/help/FAQS',
    icon: <Help />
  }
];

const LOGGEDIN_USER_NAV = [
  {
    id: 70,
    name: 'My Account',
    path: '/customer/account/profile',
    icon: <PersonOutline />
  },
  {
    id: 38,
    name: 'Shop Now',
    path: '/products/all',
    icon: <ShoppingBasketOutlined />
  },
  {
    id: 35,
    name: 'Swag Shopping',
    path: '/swag-store',
    icon: <ShopOutlined />
  },
  {
    id: 22,
    name: 'My Orders',
    path: '/customer/account/my-orders',
    icon: <Share />
  },
  {
    id: 244,
    name: 'My Co-operative',
    path: coopDashboard(),
    icon: <Business />
  },
  {
    id: 7,
    name: 'Contact',
    path: '/contact-us',
    icon: <ContactPhone />
  }
];
const activeMenu = {
  color: '#FFCC2A'
};

const navLinksData = [
  {
    name: 'Home',
    path: '/'
  },
  {
    name: 'About',
    path: ABOUT
  },
  {
    name: 'Shop',
    path: '/products/all'
  },
  {
    name: 'Help Center',
    path: HELP_CENTER
  },
  {
    name: 'Contact Us',
    path: CONTACT
  },
  {
    name: 'My Cooperative',
    path: coopDashboard()
  },
];

const userRoutes = {
  userLogin: USER_LOGIN,
  userProfile: USER_PROFILE,
  userOrders: USER_ORDERS,
  shopUrl: '/products/all',
  home: USER_HOME,
  productView: '/product-details'
};

export {
  activeMenu,
  userRoutes,
  navLinksData,
  LOGGEDIN_USER_NAV,
  LOGGEDOUT_USER_NAV
};

/** ***
 * Side Menu styles Object Material-UI
 */
