export default (theme) => (
  {
    root: {
      maxWidth: 220,
      margin: theme.spacing(2, 'auto'),
      [theme.breakpoints.down('sm')]: {
        maxWidth: 250,
      },
    },
    itemTitle: {
      textTransform: 'capitalize',
    },
    box: {
      margin: theme.spacing(0.5, 0),
      '& > * ': {
        margin: theme.spacing(0.3, 0),
      }
    },
    discount: {
      display: 'block',
      borderRadius: '.3rem',
      padding: theme.spacing(0.3),
      color: theme.palette.white,
      background: theme.palette.tetiary.contrastText,
      fontSize: theme.typography.pxToRem('12')
    },
    marketPrice: {
      textDecoration: 'line-through',
    },
    btn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(0.2)
    }
  }
);
