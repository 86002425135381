class ProductUtils {
  static getProductTotal(item) {
    return item.reduce((a, b) => a + b.qty * b.price, 0);
  }

  static calcVariantsTotal(variants) {
    const item = variants.map(((list) => ({
      totalPrice: list.requestQty * list.price
    }))).reduce((a, b) => a + b.totalPrice, 0);
    return item;
  }

  static calCartTotalItems(cart) {
    const data = cart.map((list) => ({
      ...list,
      totalPrice: list.variants
        .map((item) => item.requestQty * item.price)
        .reduce((a, b) => a + b, 0)
    })).reduce((a, b) => a + b.totalPrice, 0);
    return data;
  }

  static convert2TwoDecimal(value) {
    return Number(`${Math.round(`${value}e2`)}e-2`);
  }

  static getSpecificStates() {
    [{ name: 'Rivers', id: 1 }, { name: 'Lagos', id: 2 },
    { name: 'Aba', id: 3 }]
      .filter((item) => (
        item.name
          .includes(['Lagos', 'Rivers'])
      ));
    return [];
  }

  static slicedText(item) {
    return item.split(' ').slice(0, 3).join(' ');
  }
}

export default ProductUtils;
