import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header/MainHeader';
import Footer from './Footer/MainFooter';
import LayoutWrapper from '../../shared/LayoutWrapper/LayoutWrapper';

const MainLayout = ({ children }) => {
  return (
    <LayoutWrapper
      header={<Header />}
      footer={<Footer />}
    >
      {children}
    </LayoutWrapper>
  );
};

MainLayout.prototype = {
  children: PropTypes.node.isRequired
};

export default MainLayout;
