import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Content from '../../components/content/Content';
import Category from '../../components/content/Category';
import SubScribe from '../../components/content/SubScribe/SubScribe';
import GettingStarted from '../../components/content/GettingStarted/GettingStarted';
import ProductView from '../../components/Products/ProductViews/ProductView';
import useGetQuery from '../../hooks/useGetQuery';
import { BASE_URL } from '../../services/config';
import Page from '../../layouts/Page/Page';
import UserUtils from '../../utils/functions/users/userUtils';

const description = `The FoodCrowdy team is always 
committed to giving you a unique shopping experience
 from deep discounts to doorstep delivery, we want to ensure you
  are always happy with our services. Bulk Buy and Save Big,
  FoodCrowdy Buy more spend less same great quality Smart buyer
  
  `;

const Home = () => {
  const { viewedProducts, currentState } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state.userAuth);
  const { data, isLoading, refetch } = useGetQuery({
    baseUrl: BASE_URL.COOPERATIVE_AUTH,
    endpoint: `/products?page=1&perPage=20${currentState ? `&state=${currentState}` : ''}`,
    queryKey: 'allCoops',
    options: {
      retry: false,
      refetchOnWindowFocus: false,
    }
  });

  const routeToShop = () => {
    const coopType = UserUtils.userAuthType(user?.cooperative);
    switch (coopType) {
      case 'COOPEAST':
        return '/coopeast/products';
      case 'MEMCOS':
        return '/mtn-memcos-cooperative/all';
      default:
        return '/products/all';
    }
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState]);

  return (
    <Page
      title="FoodCrowdy | Home "
      nameContent={description}
      propertyTitleContent="FoodCrowdy Home"
    >
      <Content />
      <Category />
      <ProductView
        title="Latest Products"
        link={routeToShop()}
        isLoading={isLoading}
        data={data?.data?.docs}
      />
      <ProductView
        type="viewed"
        link={routeToShop()}
        title="Recently Viewed"
        isLoading={isLoading}
        data={viewedProducts}
      />
      <GettingStarted />
      <SubScribe />
    </Page>
  );
};

export default Home;
