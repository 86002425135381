import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { Hidden, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { dashboardStyles } from '../Styles';
import Logo from '../../../shared/Header/Logo/Logo';
import { appBrands } from '../../../constants/Brands';
import UserUtils from '../../../utils/functions/users/userUtils';

const styles = makeStyles((theme) => (dashboardStyles(theme)));

const WithAuthBar = (WrappedComponent) => {
  const hocComponent = (props) => {
    const classes = styles();
    const { isLogged, open } = props;
    if (!isLogged) {
      return (
        <AppBar
          position="fixed"
          style={{ background: '#fff' }}
          color="inherit"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Logo
              homeUrl="##"
              customLogo={appBrands.customLogo}
            />
            <div className={classes.flexGrow} />
          </Toolbar>
        </AppBar>
      );
    }
    return (
      <WrappedComponent
        {...props}
      />
    );
  };

  return hocComponent;
};

const AppBarView = ({
  open, handleDrawerOpen, classes,
}) => {
  const { userLogout } = UserUtils;
  const history = useHistory();
  return (
    <AppBar
      position="fixed"
      color="secondary"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          Dashboard
        </Typography>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton
            onClick={(e) => userLogout(e, history)}
            className={classes.signOutButton}
            color="inherit"
          >
            <InputIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>

  );
};

export default WithAuthBar(AppBarView);
