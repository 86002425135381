import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { layoutStyle } from './Style';

const useStyles = makeStyles((theme) => (layoutStyle(theme)));

const LayoutWrapper = ({ children, header, footer }) => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <div className={classes.wrapper}>
        {header}
        <main className={classes.main}>
          {children}
        </main>
      </div>
      {footer}
    </div>
  );
};

LayoutWrapper.prototype = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired
};

export default LayoutWrapper;
