export const appBrands = {
  emailAddress: 'info@foodcrowdy.com',
  companyName: 'FoodCrowdy',
  companyAddress: ' No 4 Eliada Close, Off Okporo Road Artillery, Port Harcourt, Rivers Ng',
  companyPhoneNumber: '+234 8117001514',
  logisticsName: 'Food Crowdy',
  supportMail: 'info@foodcrowdy.com',
  authBackgroundImage: 'https://res.cloudinary.com/cmcwebcode/image/upload/v1596039716/foodcrowdy/signup-copy-2_1_tixv0n.jpg',
  publicIcon: 'https://res.cloudinary.com/accelerar/image/upload/v1598101328/Html_Order_Image/logo192_uacgcd.png',
  customLogo: 'https://res.cloudinary.com/cmcwebcode/image/upload/v1596211234/foodcrowdy/LOGO_FOODCROWDY_pa3ciy.png',
  cooperative: {
    shellCoopEast: {
      name: 'Shell Coop East',
      logoUrl: 'https://res.cloudinary.com/accelerar/image/upload/v1603838756/cooperative/bpkaukviorxlmppqmzgk.png'
    },
    mtnMemCos: {
      name: 'MEMOS',
      logoUrl: 'https://res.cloudinary.com/accelerar/image/upload/v1606312817/Cooperatives/Memcos_y0hniq.png'
    },
    cashToken: {
      name: 'Cash Token',
      logoUrl: 'https://res.cloudinary.com/accelerar/image/upload/v1611927431/cooperative/cashtoken_pdpqgl.png'
    }
  },
  delivery: {
    typeOptions: {
      van: 180,
      truck: 300,
      motorCycle: 150,
    }
  },
  cart: {
    warning:
      `
    Please you cannot add products
    from different location at a time, please select from 
    your current state or clear your cart and
    select from the state of your choice.  
    `
  },
};
