import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleError } from '../../../errors/ErrorMgs';
import { onSuccess } from '../../../redux/reducers/auth';
import { FoodCrowdyApi } from '../../../services/config';
import _ from '../../../utils/functions/users/userUtils';
import { contextApi } from '../../context/Context';

const useCoopLogin = (dashboardPath) => {
  const { notifications, setNotifications } = useContext(contextApi);
  const [formState, setFormState] = useState({
    email: '',
    password: ''
  });
  const history = useHistory();
  const [otp, setOTp] = useState('');
  const [open, setOpen] = useState(false);
  const [retry, setRetry] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState('');
  const [pmethod, setpmethod] = useState('2');
  const [isVerified, setIsVerifed] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setOTp(event.target.value);
  };

  const handleLoginType = (e) => {
    setMessage('');
    setpmethod(e.target.value);
  };

  const handleChangeForm = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const sendAccountConfirmationLink = async (e) => {
    e.preventDefault();
    const data = {
      phoneNumber,
      otp
    };
    setLoading(true);
    try {
      await FoodCrowdyApi.post('/auth/verify-otp', data);
      history.push(`/cooperatives/registration?userNumber=${phoneNumber}`);
    } catch (error) {
      const { message: warning } = handleError(error);
      setMessage(warning);
    }
    setLoading(false);
  };

  setTimeout(() => {
    setRetry(true);
  }, 180 * 1000);

  const userVerification = async (e) => {
    e.preventDefault();
    setMessage('');
    if (!phoneNumber || !phoneNumber.length === 13) return setMessage('Please Enter a valid phone number');
    const validePhoneNumber = phoneNumber.replace('+', '');
    setPhoneNumber(validePhoneNumber);
    setLoading(!loading);
    try {
      await FoodCrowdyApi.post('/auth/send-verification-otp', { phoneNumber: validePhoneNumber });
      setIsVerifed(true);
    } catch (error) {
      const { message: warning } = handleError(error);
      setMessage(warning);
    }
    setLoading(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage('');
    setLoading(!loading);
    try {
      const res = await FoodCrowdyApi.post('/auth/login', formState);
      const { token, userData } = _.authPrepareData(res);
      dispatch(onSuccess({ token, userData }));
      setNotifications(!notifications);
      if (res?.data?.data.cooperativeId) {
        history.replace(dashboardPath);
      } else {
        setMessage('something went wrong');
      }
    } catch (error) {
      const { message: errorData } = handleError(error);
      // dispatch(onError({ payload: errorData }));
      setMessage(errorData);
    }
    setLoading(false);
  };

  return {
    open,
    retry,
    loading,
    setOpen,
    message,
    pmethod,
    formState,
    setMessage,
    isVerified,
    phoneNumber,
    handleLogin,
    setIsVerifed,
    handleChange,
    setPhoneNumber,
    handleLoginType,
    handleChangeForm,
    userVerification,
    sendAccountConfirmationLink
  };
};

export default useCoopLogin;
