/* eslint-disable max-len */
import { lazy } from 'react';
import { mainRoutes } from '../../routes.path';

/**
 * Layouts for Routes
 */

import MainLayouts from '../../../layouts/MainLayout/MainLayout';

/**
 *  ?? Food Crowdy Landing Page routes
 */

// TODO: Remember to revert MEMCOS home page you put up for test

import Home from '../../../pages/Home/Home';
import CooperativesMemCosAuth from '../../../pages/Cooperatives/memcosAuth/MemcoLogin';

const ContactUs = lazy(() => import('../../../pages/Contact/Contact'));
const AboutUs = lazy(() => import('../../../pages/About/AboutUs'));
const Help = lazy(() => import('../../../pages/FAQs/Help'));
const SignIn = lazy(() => import('../../../pages/Auth/SignIn/SignIn'));

/**
 * @website Policies
 */

const PrivacyPolicy = lazy(() => import('../../../pages/PrivacyPolicy/PrivacyPolicy'));
const TermsAndCondition = lazy(() => import('../../../pages/Term&Conditions/TermsConditions'));
const ReturnPolicy = lazy(() => import('../../../pages/ReturnPolicy/ReturnPolicy'));

const ForgotPassword = lazy(() => import('../../../pages/ForgotPassword/ForgotPassword'));
const PasswordReset = lazy(() => import('../../../pages/ForgotPassword/ResetPassword'));
const EmailVerification = lazy(() => import('../../../pages/EmailVerification/EmailVerification'));
const SignUp = lazy(() => import('../../../pages/Auth/SignUp/Signup'));

//* ***search component routes for different view */
const ProductCategory = lazy(() => import('../../../pages/Products/ProductCategory'));
const ProductDetails = lazy(() => import('../../../pages/ProductsDetails/ProductDetails'));

/**
 * @User Private Routes
 */
// const UsersView = lazy(() => import('../../../pages/UsersView'));
const UserAccountDetails = lazy(() => import('../../../pages/UserDashboard/accountProfile/AccountProfile'));
const MyOrders = lazy(() => import('../../../pages/UserDashboard/orders/MyOrders'));
const UserReferrals = lazy(() => import('../../../pages/UserDashboard/referralsPoints/ReferralPoints'));
const SwagPoints = lazy(() => import('../../../pages/UserDashboard/SwagPoints/SwagPoints'));
const UserLogistics = lazy(() => import('../../../pages/UserDashboard/Logistics/Logistics'));
const UserWallet = lazy(() => import('../../../pages/UserDashboard/Wallet/UserWallet'));

/**
 * Checkout Types
 */
const CheckoutViews = lazy(() => import('../../../pages/checkout/Checkout'));
const PaymentVerification = lazy(() => import('../../../pages/PaymentVerification/PaymentVerification'));

/**
 * Co Operatives
 */
const COoperatives = lazy(() => import('../../../pages/Cooperatives/Cooperatives'));

/**
 * Claim Coupon Code...
 */

const ClaimCoupon = lazy(() => import('../../../pages/ClaimCoupon/ClaimCoupon'));

const CooperativesCoopEastAuth = lazy(() => import('../../../pages/Cooperatives/coopEast/Login'));

const {
  CONTACT, CE_LOGIN, MC_LOGIN,
  PRIVACY_POLICY, RETURN_POLICY,
  COOP_LOGIN, TERMS_AND_CONDITION, PRODUCTS,
  ABOUT, CHECKOUT_ORDERS, CLAIM_COUPON_CODE,
  HELP_CENTER, NEW_PASSWORD,
  PAYMENT_VERIFICATION, PRODUCT_DETAILS_VIEW,
  RESET_PASSWORD, SWAGS, USER_HOME, WALLET,
  USER_LOGIN, USER_LOGISTICS, USER_ORDERS, USER_PROFILE,
  USER_REFERRALS, USER_REGISTER, VERIFY_EMAIL
} = mainRoutes;

export const mainAppRoutes = [
  {
    path: CONTACT,
    layout: MainLayouts,
    component: ContactUs
  },
  {
    path: PRIVACY_POLICY,
    layout: MainLayouts,
    component: PrivacyPolicy
  },
  {
    path: TERMS_AND_CONDITION,
    layout: MainLayouts,
    component: TermsAndCondition
  },
  {
    path: RETURN_POLICY,
    layout: MainLayouts,
    component: ReturnPolicy
  },
  {
    path: ABOUT,
    layout: MainLayouts,
    component: AboutUs
  },
  {
    path: HELP_CENTER,
    layout: MainLayouts,
    component: Help
  },
  {
    path: USER_LOGIN,
    layout: MainLayouts,
    component: SignIn
  },
  {
    path: USER_REGISTER,
    layout: MainLayouts,
    component: SignUp
  },
  {
    path: PRODUCTS,
    layout: MainLayouts,
    component: ProductCategory
  },
  {
    path: PRODUCT_DETAILS_VIEW,
    layout: MainLayouts,
    component: ProductDetails
  },
  {
    path: CLAIM_COUPON_CODE,
    layout: MainLayouts,
    component: ClaimCoupon
  },
  {
    path: NEW_PASSWORD,
    layout: MainLayouts,
    component: ForgotPassword
  },
  {
    path: RESET_PASSWORD,
    layout: MainLayouts,
    component: PasswordReset
  },
  {
    path: VERIFY_EMAIL,
    layout: MainLayouts,
    component: EmailVerification
  },
  {
    path: USER_HOME,
    layout: MainLayouts,
    component: Home
  },
  {
    path: CE_LOGIN,
    layout: MainLayouts,
    component: CooperativesCoopEastAuth
  },
  {
    path: MC_LOGIN,
    layout: MainLayouts,
    component: CooperativesMemCosAuth
  },
];

export const mainAppPrivateRoutes = [
  {
    path: COOP_LOGIN,
    layout: MainLayouts,
    component: COoperatives,
    authPath: USER_LOGIN
  },
  {
    path: SWAGS,
    layout: MainLayouts,
    component: SwagPoints,
    authPath: USER_LOGIN
  },
  {
    path: CHECKOUT_ORDERS,
    layout: MainLayouts,
    component: CheckoutViews,
    authPath: USER_LOGIN
  },
  {
    path: PAYMENT_VERIFICATION,
    layout: MainLayouts,
    component: PaymentVerification,
    authPath: USER_LOGIN
  },
  {
    path: USER_REFERRALS,
    layout: MainLayouts,
    component: UserReferrals,
    authPath: USER_LOGIN
  },
  {
    path: USER_PROFILE,
    layout: MainLayouts,
    component: UserAccountDetails,
    authPath: USER_LOGIN
  },
  {
    path: USER_LOGISTICS,
    layout: MainLayouts,
    component: UserLogistics,
    authPath: USER_LOGIN
  },
  {
    path: USER_ORDERS,
    layout: MainLayouts,
    component: MyOrders,
    authPath: USER_LOGIN
  },
  {
    path: WALLET,
    layout: MainLayouts,
    component: UserWallet,
    authPath: USER_LOGIN
  },
];
