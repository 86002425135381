export const Style = (theme) => ({
  root: {
    margin: theme.spacing(1, 'auto'),
    padding: theme.spacing(4, 0.3),
    display: 'flex',
    height: '100%',
  },
  card: {
    backgroundColor: theme.palette.secondary.light
  },
  cardContent: {
    maxWidth: 400,
    color: theme.palette.white
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  button: {
    width: '100%'
  },
  imagesWrapper: {
    position: 'relative',
    zIndex: 1
  },
  image: {
    height: '100%',
    width: '100%',
    maxWidth: 600
  }
});
