import React from 'react';
import { ToastContainer } from 'react-toastify';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ContextProvider from './components/context/Context';
import WhatsAppChat from './components/whatsappChat/WhatsAppChat';
import ErrorBoundary from './pages/ErrorBoundary/ErrorBoundary';
import ChooseState from './components/alertMessage/ChooseState/ChooseState';
import Routes from './routes/Routes';
import './GoogleAnalytics/GoogleAnalytics';
import './libs';
import './sass/main.scss';

const queryClient = new QueryClient();

function App() {
  const { app } = useSelector((state) => state.platform);

  const themeConfig = createMuiTheme({
    palette: app.theme,
    typography: {
      fontSize: 11,
      fontWeight: 400,
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    }
  });

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ContextProvider>
            <ThemeProvider theme={themeConfig}>
              <ChooseState />
              <Routes />
              <ToastContainer
                hideProgressBar
              />
              <WhatsAppChat />
            </ThemeProvider>
          </ContextProvider>
          <ReactQueryDevtools />
        </Router>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
export default App;
