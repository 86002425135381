import { lazy } from 'react';
import CoopEastLayout from '../../../layouts/CoopEast/CoopEast';
import { coopEastRoutes } from '../../routes.path';

const CooperativesCoopEastAuth = lazy(() => import('../../../pages/Cooperatives/coopEast/Login'));
const CoopEastHelp = lazy(() => import('../../../pages/CoopEastCooperative/HelpCenter/HelpCenter'));
const CoopEastContact = lazy(() => import('../../../pages/CoopEastCooperative/Contact/Contact'));
const CooperativesCheckout = lazy(() => import('../../../pages/CoopEastCooperative/Checkout/Checkout'));
const CoopEastAbout = lazy(() => import('../../../pages/CoopEastCooperative/About/About'));
const CoopEastProducts = lazy(() => import('../../../pages/CoopEastCooperative/Products/Products'));
const CoopEastOrders = lazy(() => import('../../../pages/CoopEastCooperative/Orders/CoopEastOrders'));
const CoopEastProductsDetails = lazy(() => import('../../../pages/CoopEastCooperative/Products//ProductDetails'));
const CoopEastProfile = lazy(() => import('../../../pages/CoopEastCooperative/ProfileAccount/ProfileAccount'));
const CooperativesDashboard = lazy(() => import('../../../pages/CoopEastCooperative/Dashboard/Cooperatives'));
const CooperativesVerifyPayment = lazy(() => import('../../../pages/PaymentVerification/CooperativeVerifyPayment'));

const {
  CE_ABOUT, CE_ALL_PRODUCTS,
  CE_CHECKOUT, CE_CONTACT,
  CE_DASHBOARD, CE_HELP_CENTER, CE_LOGIN,
  CE_ORDERS, CE_PAYMENT_VERIFICATION,
  CE_PRODUCTS_DETAILS, CE_PROFILE
} = coopEastRoutes;

export const coopEastAppRoutes = [
  {
    path: CE_LOGIN,
    layout: CoopEastLayout,
    component: CooperativesCoopEastAuth
  },
  {
    path: CE_CONTACT,
    layout: CoopEastLayout,
    component: CoopEastContact
  },
  {
    path: CE_HELP_CENTER,
    layout: CoopEastLayout,
    component: CoopEastHelp
  },
  {
    path: CE_ABOUT,
    layout: CoopEastLayout,
    component: CoopEastAbout
  },
];

export const coopEastAppPrivateRoutes = [
  {
    path: CE_ALL_PRODUCTS,
    layout: CoopEastLayout,
    component: CoopEastProducts,
    authPath: CE_LOGIN
  },
  {
    path: CE_ORDERS,
    layout: CoopEastLayout,
    component: CoopEastOrders,
    authPath: CE_LOGIN
  },
  {
    path: CE_PRODUCTS_DETAILS,
    layout: CoopEastLayout,
    component: CoopEastProductsDetails,
    authPath: CE_LOGIN
  },
  {
    path: CE_DASHBOARD,
    layout: CoopEastLayout,
    component: CooperativesDashboard,
    authPath: CE_LOGIN
  },
  {
    path: CE_PROFILE,
    layout: CoopEastLayout,
    component: CoopEastProfile,
    authPath: CE_LOGIN
  },
  {
    path: CE_PAYMENT_VERIFICATION,
    layout: CoopEastLayout,
    component: CooperativesVerifyPayment,
    authPath: CE_LOGIN
  },
  {
    path: CE_CHECKOUT,
    layout: CoopEastLayout,
    component: CooperativesCheckout,
    authPath: CE_LOGIN
  },
];
