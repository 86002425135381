import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowForward } from '@material-ui/icons';
import {
  IconButton,
  Button,
  makeStyles,
  Typography,
  Box
} from '@material-ui/core/';
import { useHistory } from 'react-router-dom';
import CoopCard from './Cards/CartCards';
import { formatter } from '../../utils/formatters/formatter';
import UserUtils from '../../utils/functions/users/userUtils';
import ProductUtils from '../../utils/functions/products/productUtils';

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: theme.spacing(1)
  },
  btnBlock: {
    margin: theme.spacing(0.7),
    padding: theme.spacing(0.5, 'auto')

  }
}));

export default function CartDrawer(prop) {
  const { toggleDrawer } = prop;
  const classes = useStyles();
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const { cart } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.userAuth);

  const routeToShop = () => {
    toggleDrawer();
    const coopType = UserUtils.userAuthType(user?.coopId);
    switch (coopType) {
      case 'COOPEAST':
        history.push('/coopeast/products');
        break;
      case 'MEMCOS':
        history.push('/mtn-memcos-cooperative/all');
        break;
      default:
        history.push('/products/all');
    }
  };

  const routeToCheckout = () => {
    toggleDrawer();
    const coopType = UserUtils.userAuthType(user?.coopId);
    switch (coopType) {
      case 'COOPEAST':
        history.push('/checkout/coopeast');
        break;
      case 'MEMCOS':
        history.push('/checkout/memcos');
        break;
      default:
        history.push('/checkout-cart');
    }
  };

  useEffect(() => {
    const _func = ProductUtils;
    const cartTotalAmount = _func.calCartTotalItems(cart);
    setTotal(cartTotalAmount);
  }, [cart]);

  const Checkout = (totalAmount, cart) => {
    return (
      <>
        {cart.length ? (
          <div className={classes.btnBlock}>
            <Typography
              variant="h5"
              align="right"
              component="h5"
            >
              TOTAL :
              {formatter.format(totalAmount)}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                className={classes.btn}
                onClick={routeToShop}
                variant="outlined"
                fullWidth
                color="secondary"
              >
                <strong>SHOPPING</strong>
              </Button>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className={classes.btn}
                onClick={routeToCheckout}
              >
                <strong> CHECKOUT</strong>
              </Button>
            </Box>
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  const CartContents = () => {
    return (
      <>
        <CoopCard
          cartDisplay={cart}
        />
        {Checkout(total, cart)}
      </>
    );
  };

  return (
    <div className="cart">
      <div className="close-cart">
        <IconButton
          aria-label="ArrowForward"
          onClick={toggleDrawer}
        >
          <ArrowForward />
        </IconButton>
      </div>
      <div>{CartContents()}</div>
    </div>
  );
}
