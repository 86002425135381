export default (theme) => {
  return {
    root: {
      height: '100%'
    },
    searchBtnSmall: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
    searchBtnLarge: {
      color: theme.palette.black,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
  };
};

export const searchedResultStyles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.white,
    display: 'block'
  },
  paper: {
    margin: '0 auto ',
    position: 'absolute',
    borderRadius: '.5rem',
    width: '36%',
    zIndex: '3',
    '& h4': {
      margin: '.5rem .9rem',
      padding: '.1rem 0'
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    }
  },
  list: {
    margin: '0 .4rem '
  },
  link: {
    textAlign: 'center',
    display: 'block',
    padding: '1rem 1rem ',
    backgroundColor: theme.palette.white
  },
  hide: {
    display: 'none'
  },
  text: {
    '&:first-letter': {
      textTransform: 'capitalize'
    }
  },
  close: {
    height: '2rem',
    width: '2rem',
    backgroundColor: theme.palette.white,
    boxShadow: theme.shadows[2],
    textAlign: 'right',
    borderRadius: '50%',
    position: 'absolute',
    top: '0%',
    right: '0%',
    margin: '.5rem 1rem ',
    cursor: 'pointer'
  }
});
