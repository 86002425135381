export const Styles = {
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  close: {
    margin: '.5rem 2rem  0 2rem '
  },
  anchorTag: {
    color: '#008080'
  },
  btn: {
    margin: '.4rem .8rem ',
    padding: '.4rem 1.5rem '
  },
  avatar: {
    textAlign: 'center',
    margin: '.2rem .7rem '
  },
  listIcon: {
    margin: '0 .1rem '
  }
};

export const CartStyles = (theme) => ({
  icon: {
    color: theme.palette.tetiary.main,
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.black,
    }
  }
});
