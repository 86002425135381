export const officeAddress = [
  {
    name: 'Portharcourt',
    id: 1,
    state: 'Rivers',
    label: 'Eliada Close Phc, Okporo Road, Port Harcourt'
  },
  {
    name: 'Lagos',
    state: 'Lagos',
    id: 2,
    label: 'Spark light Housing Estate, Ojodu, Lagos'
  }
];

export const statesAvailable = [{ name: 'All' }, { name: 'Lagos' }, { name: 'Rivers' }];

export const stateLocation = [
  {
    id: 1,
    name: 'Lagos'
  },
  {
    id: 2,
    name: 'Portharcourt'
  },
];

export default [
  { id: 1, name: 'Abia' },
  { id: 2, name: 'Adamawa' },
  { id: 3, name: 'Akwa Ibom' },
  { id: 4, name: 'Anambra' },
  { id: 6, name: 'Bauchi' },
  { id: 7, name: 'Bayelsa' },
  { id: 8, name: 'Benue' },
  { id: 10, name: 'Cross River' },
  { id: 11, name: 'Delta' },
  { id: 12, name: 'Ebonyi' },
  { id: 13, name: 'Edo' },
  { id: 14, name: 'Ekiti' },
  { id: 15, name: 'Enugu' },
  { id: 16, name: 'Gombe' },
  { id: 17, name: 'Imo' },
  { id: 18, name: 'Jigawa' },
  { id: 19, name: 'Kaduna' },
  { id: 20, name: 'Kano' },
  { id: 21, name: 'Katsina' },
  { id: 22, name: 'Kebbi' },
  { id: 23, name: 'Kogi' },
  { id: 24, name: 'Kwara' },
  { id: 25, name: 'Lagos' },
  { id: 26, name: 'Nasarawa' },
  { id: 27, name: 'Niger' },
  { id: 28, name: 'Ogun' },
  { id: 29, name: 'Ondo' },
  { id: 30, name: 'Osun' },
  { id: 31, name: 'Oyo' },
  { id: 32, name: 'Plateau' },
  { id: 33, name: 'Rivers' },
  { id: 34, name: 'Sokoto' },
  { id: 35, name: 'Taraba' },
  { id: 36, name: 'Yobe' },
  { id: 37, name: 'Zamfara' }
];

export const days = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31
];

export const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
