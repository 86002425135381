import React from 'react';
import Footer from '../../../shared/Footer/Footer';
import { FOOTER_NAV } from './dataList';

const CEFooter = () => {
  return (
    <Footer
      FooterNav={FOOTER_NAV}
    />
  );
};

export default CEFooter;
