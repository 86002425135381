import React, { Fragment, useState } from 'react';
import {
  Button, makeStyles
} from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import { Alert } from '@material-ui/lab';
import CustomModal from '../custommodal/CustomModal';
import {
  addToCart,
  addVariant,
  decrementVariantQty,
  incrementVariantQty,
  removeVariant
} from '../../redux/reducers/Cart';
import { Style } from './style/AddToCart';
import Variants from '../cart/Variants/Variants';
import { appBrands } from '../../constants/Brands';

const useStyles = makeStyles((theme) => Style(theme));

const ButtonProps = {
  variant: 'outlined',
  color: 'secondary'
};

const CptAddToCart = ({
  item: cartItem,
  addForm, setAddForm
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const { variants, cart } = useSelector((state) => state.cart);

  const incrementQty = (value) => {
    dispatch(incrementVariantQty(value?._id));
  };

  const decrementQty = (value) => {
    dispatch(decrementVariantQty(value?._id));
  };
  const storeItemInCart = () => {
    if (cart.length) {
      const productLocation = cart.find((item) => (
        item.productState === cartItem.state
      ));
      if (!productLocation) {
        setMessage(appBrands.cart.warning);
        return;
      }
    }
    dispatch(addToCart(cartItem));
    setAddForm(!addForm);
  };

  const handleVariant = (event, item) => {
    const { checked } = event.target;
    if (checked) {
      const initialQty = {
        ...item,
        productId: cartItem._id,
        requestQty: 1,
      };
      dispatch(addVariant(initialQty));
    } else {
      dispatch(removeVariant(item?._id));
    }
  };

  const getQty = (value) => {
    const qtyItem = (variants || []).find((item) => (item._id === value._id));
    return {
      qty: qtyItem?.requestQty || 1,
      totalPrice: qtyItem?.total || 0
    };
  };

  const ActionButton = (
    <Button
      className={classes.btnCart}
      color="primary"
      variant="contained"
      onClick={storeItemInCart}
      endIcon={<ShoppingCart />}
    >
      ADD TO CART
    </Button>
  );

  return (
    <CustomModal
      addCloseIcon
      open={addForm}
      setOpen={setAddForm}
      outBoxClose
      ButtonProps={ButtonProps}
      bottomChild={ActionButton}
      modalTitle="Add Item to your cart"
    >
      {message && (
        <Alert severity="info">
          {message}
        </Alert>
      )}
      <List className={classes.root}>
        {cartItem?.platform[0]?.variant.map((value) => {
          const labelId = `checkbox-list-label-${value?._id}`;
          return (
            <Fragment key={value?._id}>
              <Variants
                item={value}
                quantity={getQty(value).qty}
                labelId={labelId}
                incrementQty={incrementQty}
                decrementQty={decrementQty}
                handleVariant={handleVariant}
              />
            </Fragment>
          );
        })}
      </List>
    </CustomModal>
  );
};

export default CptAddToCart;
