import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import CarouselBanner from './Banners/CarouselBanner';
import { BASE_URL } from '../../services/config';
import useGetQuery from '../../hooks/useGetQuery';
import { Banners1, Banners2, Banners3 } from './data/images';
import BannerAppShell from './Banners/BannerAppShell';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0.7),
  },
  item1: {
    display: 'none',
    borderRadius: '.4rem',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
    '& img': {
      margin: theme.spacing(1, 'auto'),
      backgroundSize: 'cover',
      borderRadius: '.4rem',
      maxWidth: 3000,
      width: 870,
      height: 'calc(100%- 70%)'
    }
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
  item2: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    ' & > div': {
      margin: theme.spacing(1, 0.5)
    },
    '& img': {
      margin: theme.spacing(0.2, 0),
      width: '100%',
      backgroundSize: '100% 100%',
      borderRadius: '.4rem',
    }
  },
  slideMobile: {
    height: 200,
    margin: theme.spacing(0.2, 0)
  },
}));

function Content() {
  const classes = useStyles();
  const { isLoading } = useGetQuery({
    baseUrl: BASE_URL.COOPERATIVE_AUTH,
    endpoint: '/platform',
    queryKey: 'carousels',
    options: {
      retry: false,
      refetchOnWindowFocus: false,
    }
  });

  if (isLoading) {
    return (
      <BannerAppShell />
    );
  }

  return (
    <>
      <div className={classes.mobile}>
        <CarouselBanner
          arrows
          data={Banners1}
          autoPlaySpeed={3000}
          className={classes.slideMobile}
        />
      </div>
      <Grid
        container
        className={classes.root}
      >
        <Grid
          item
          md={8}
          className={classes.item1}
        >
          <CarouselBanner
            arrows
            data={Banners1}
            autoPlaySpeed={6000}
          />
        </Grid>
        <Grid
          item
          md={3}
          className={classes.item2}
        >
          <div>
            <CarouselBanner
              data={Banners2}
              autoPlaySpeed={15000}
            />
          </div>
          <div>
            <CarouselBanner
              data={Banners3}
              autoPlaySpeed={12000}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Content;
