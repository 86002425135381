import { useQuery } from 'react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
// import { useHistory } from 'react-router-dom';
import { handleError } from '../errors/ErrorMgs';
import UserUtils from '../utils/functions/users/userUtils';

export default function useGetQuery({
  baseUrl, endpoint,
  options = {},
  queryKey, /* isAuth = true */
}) {
  const { headers } = UserUtils.userToken();
  // const history = useHistory();
  const isLoggedIn = headers['x-auth-token'];
  options.retry = false;
  options.refetchOnWindowFocus = false;
  const getRequest = async () => {
    const { data } = await axios.get(`${baseUrl}${endpoint}`, isLoggedIn !== 'undefined' && {
      headers
    });
    return data;
  };

  const { error } = useQuery(queryKey, getRequest, options);
  if (error) {
    const { message } = handleError(error);
    // UserUtils.userLogout({}, history);
    toast.error(message, { toastId: 'error' });
  }

  return useQuery(queryKey, getRequest, options);
}

// useGetQuery.defaultProps = {
//   options: {
//     retry: false,
//     refetchOnWindowFocus: false,
//   }
// };

useGetQuery.propTypes = {
  isAuth: PropTypes.bool,
  options: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
};
