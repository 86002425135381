import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  appShell: {
    margin: theme.spacing(0.4),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 290,
      margin: theme.spacing(0.6, 'auto'),
    },
  },
  skeleton: {
    margin: theme.spacing(0.8, 1.4)
  },
  appShell2: {
    margin: theme.spacing(0.4),
    '& >*': {
      margin: theme.spacing(1, 0.5),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const BannerAppShell = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.skeleton}
    >
      <Grid
        item
        className={classes.appShell}
        md={8}
      >
        <Skeleton
          variant="rect"
          className={classes.appShell}
          width={900}
          height={300}
        />
      </Grid>
      <Grid
        item
        className={classes.appShell2}
        md={3}
      >
        <Skeleton
          variant="rect"
          width={300}
          height={180}

        />
        <Skeleton
          variant="rect"
          width={300}
          height={120}
        />
      </Grid>
    </Grid>
  );
};

export default BannerAppShell;
