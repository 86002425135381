/* eslint-disable indent */
import Cookies from 'js-cookie';
import ENV_KEYS from '../../../config';
import {
 COOKIE_KEY_V1, COOKIE_KEY_V2, roles, USER_AUTH_TOKEN
} from '../../data/constants/constants';

class UserUtils {
  /**
   *
   * USER AUTHENTICATION-FLOW UTILITY FUNCTION
   * 1. saveUserDetails - Save user Details in required object format into browser localStorage
   * 2. authPrepareData - Purify server response and avoiding non-serialized values
   *    into the redux store
   * 3. userSessionTimeout - Listen for user cookie expirations
   * 4. saveAuthToken - Save user cookies to browser cookies.
   * 5. getUserData - Get User data by key name and full object
   * 6. userToken - Get user token from browser cookie
     7. clearUserToken - Clear user token from browser cookie
     8. clearUserData - Clear all  user token from browser storage
     9. userLogout - Logout user.
   */
  static saveUserDetails(newUser) {
    const currentUser = {};
    currentUser.id = newUser._id;
    currentUser.name = newUser.name;
    currentUser.location = newUser.location;
    currentUser.uniqueId = newUser.uniqueId;
    currentUser.email = newUser.email;
    currentUser.userName = newUser.userName;
    currentUser.cooperative = newUser.cooperativeId || newUser.platformId;
    currentUser.permission = newUser.permission;
    currentUser.staffId = newUser.staffId;
    currentUser.roles = newUser.roles;
    currentUser.wallet = newUser.wallet?.status;
    localStorage.setItem('_user', JSON.stringify(currentUser));

    return currentUser;
  }

  static authPrepareData(res) {
    const token = res.headers[COOKIE_KEY_V1] || res.headers[COOKIE_KEY_V2];
    const userData = res?.data?.data;
    return {
      token,
      userData
    };
  }

  static userSessionTimeout(authType) {
    const { type, history, location } = authType;
    const refThis = UserUtils;
    const saveCurrentRoute = (location) => {
      localStorage.setItem('__accessPath', JSON.stringify(location.pathname));
    };
    switch (type) {
      case 'INVALID_TOKEN':
        saveCurrentRoute(location);
        return refThis.userLogout(history);
      default:
        return refThis.userLogout(history);
    }
  }

  static saveAuthToken(token) {
    Cookies.set(USER_AUTH_TOKEN, token);
  }

  static getUserData(info) {
    if (localStorage.getItem('_user')) {
      try {
        const userID = JSON.parse(localStorage.getItem('_user'));
        return info ? userID[info] : userID;
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  static userToken() {
    const token = Cookies.get(USER_AUTH_TOKEN);
    return {
      headers: {
        'content-Type': 'application/json',
        'x-auth-token': `${token}`
      }
    };
  }

  static clearUserToken() {
    Cookies.remove(USER_AUTH_TOKEN);
  }

  static clearUserData = () => {
    const refThis = UserUtils;
    refThis.clearUserToken();
    localStorage.clear();
  };

  static userLogout() {
    const refThis = UserUtils;
    const isCooperative = refThis.getUserData('cooperative');
    const coopType = refThis.userAuthType(isCooperative);
    switch (coopType) {
      case 'COOPEAST': {
        refThis.clearUserData();
        window.location.href = `/cooperative/login/coopeast?cooperativeId=${ENV_KEYS.COOP_EAST_ID}`;
        break;
      }
      case 'MEMCOS': {
        window.location.href = '/cooperative/login/memcos';
        break;
      }
      default:
        refThis.clearUserData();
         window.location.href = '/customer/account/login';
         break;
    }
  }
  /**
   *
   * User Permissions
   */

  static filterCoops(data) {
    data.filter((item) => item.permission !== roles.PERMISSION[0]);
  }

  static checkCooperativeAdmin = () => {
    const refThis = UserUtils;
    const permission = refThis.getUserData('permission');
    const userRoles = refThis.getUserData('roles');
    if (permission && userRoles.length) {
      return (!!(permission === 'cooperative' && userRoles[0] === roles.COOP_ADMIN));
    }
  };

  static checkCooperativeRole = (currentRole) => {
    const refThis = UserUtils;
    const roles = refThis.getUserData('roles');
    if (roles && roles.length) return roles.find((role) => role === currentRole);
    return false;
  };

  /**
   *
   * @param {*} coopId
   * @returns respective coop Id
   */

  static userAuthType(coopId) {
    const type = {
      coopeast: 'COOPEAST',
      memcos: 'MEMCOS',
      cashToken: 'CASH_TOKEN',
    };

    let coopType = '';
    const {
     MTN_COOP_ID,
     COOP_EAST_ID,
    } = ENV_KEYS;

    if (coopId === MTN_COOP_ID) {
      coopType = type.coopeast;
    }
    if (coopId === COOP_EAST_ID) {
      coopType = type.memcos;
    }
    return coopType;
  }
}

export default UserUtils;
