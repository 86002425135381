import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FoodCrowdyApi } from '../../../services/config';
import { PLATFORM_STORE } from '../../../utils/data/constants/constants';
import _ from '../../../utils/functions/app/AppUtils';

const coops = _.getLocalStoreItem({ name: PLATFORM_STORE, type: [] });

const initialState = {
  coops,
  loading: false
};

export const getData = createAsyncThunk('all-cooperatives/getAllCooperatives', async () => {
  const response = await FoodCrowdyApi.get('/platform');
  return response.data.data;
});

const cooperatives = createSlice({
  initialState,
  name: 'all-cooperatives',
  reducers: {
    setLoader(state) {
      state.loading = !state.loading;
      return state;
    }
  },
  extraReducers: {
    [getData.pending]: (state) => {
      state.loading = true;
    },
    [getData.fulfilled]: (state, action) => {
      state.loading = false;
      state.coops = action.payload;
      _.saveLocalStoreItem({
        name: PLATFORM_STORE,
         data: action.payload
 });
    },
    [getData.rejected]: (state) => {
      state.loading = false;
    }
  }
});

export const { setLoader } = cooperatives.actions;

export default cooperatives.reducer;
