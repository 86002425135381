import React from 'react';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import { responsive } from '../../../utils/SlidersMediaQuery/MediaSlide';

const CarouselBanner = ({
  data, className,
  autoPlaySpeed,
  arrows = false
}) => {
  return (
    <Carousel
      infinite
      autoPlay
      arrows={arrows}
      responsive={responsive}
      autoPlaySpeed={autoPlaySpeed}
      removeArrowOnDeviceType="mobile"
    >
      {data.map(({ imgSrc, title, link }, index) => (
        <Link key={index++} to={link}>
          <img
            src={imgSrc}
            alt={title}
            className={className}
          />
        </Link>
      ))}
    </Carousel>
  );
};

export default CarouselBanner;
