import React from 'react';
import {
  ContactPhone,
  ShoppingBasketOutlined,
  PersonOutline,
  Help,
  Share,
  Business
} from '@material-ui/icons';
import { coopDashboard } from '../../../shared/Header/Desktop/Tabs';
import { coopEastRoutes } from '../../../routes/routes.path';

const {
  CE_CONTACT, CE_LOGIN,
  CE_HELP_CENTER,
  //  CE_ALL_PRODUCTS,
  CE_PROFILE, CE_ORDERS, CE_ABOUT
} = coopEastRoutes;

const LOGGEDOUT_USER_NAV = [
  {
    id: 3,
    name: 'Shop Now',
    path: '/coopeast/products/all',
    icon: <ShoppingBasketOutlined />
  },
  {
    id: 70,
    name: 'Contact',
    path: CE_CONTACT,
    icon: <ContactPhone />
  },
  {
    id: 7,
    name: 'Help Center',
    path: CE_HELP_CENTER,
    icon: <Help />
  }
];

const LOGGEDIN_USER_NAV = [
  {
    id: 70,
    name: 'My Account',
    path: CE_PROFILE,
    icon: <PersonOutline />
  },
  {
    id: 38,
    name: 'Shop Now',
    path: '/coopeast/products/all',
    icon: <ShoppingBasketOutlined />
  },
  {
    id: 22,
    name: 'My Orders',
    path: CE_ORDERS,
    icon: <Share />
  },
  {
    id: 244,
    name: 'My Co-operative',
    path: coopDashboard(),
    icon: <Business />
  },
  {
    id: 70,
    name: 'Contact',
    path: CE_CONTACT,
    icon: <ContactPhone />
  },
  {
    id: 7,
    name: 'Help Center',
    path: CE_HELP_CENTER,
    icon: <Help />
  }
];

const activeMenu = {
  color: '#FFCC2A'
};

const navLinksData = [
  {
    name: 'Home',
    path: '/'
  },
  {
    name: 'About',
    path: CE_ABOUT
  },
  {
    name: 'Shop',
    path: '/coopeast/products/all'
  },
  {
    name: 'Help Center',
    path: CE_HELP_CENTER
  },
  {
    name: 'Contact Us',
    path: CE_CONTACT
  },
  {
    name: 'My Cooperative',
    path: coopDashboard()
  },
];

const userRoutes = {
  userLogin: CE_LOGIN,
  userProfile: CE_PROFILE,
  userOrders: CE_ORDERS,
  shopUrl: '/coopeast/products/all',
  home: '##'
};
export {
  activeMenu,
  userRoutes,
  navLinksData,
  LOGGEDIN_USER_NAV,
  LOGGEDOUT_USER_NAV
};
