import React from 'react';
import Login from '../../../components/CooperativeAuth/Login/Login';
import { appBrands } from '../../../constants/Brands';

const MemcosLogin = (props) => {
  const {
    cooperative: {
      mtnMemCos: {
        logoUrl,
        name
      }
    }
  } = appBrands;
  return (
    <Login
      logo={logoUrl}
      title={name}
      route="/mtn-memcos-cooperative/all"
      {...props}
    />
  );
};

export default MemcosLogin;
