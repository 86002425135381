const FOOTER_NAV = [
  {
    id: 1,
    name: 'Home',
    path: '/'
  },
  {
    id: 2,
    name: 'About',
    path: '/about-us'
  },
  {
    id: 3,
    name: 'Shop Now',
    path: '/products/all'
  },
  {
    id: 7,
    name: 'Contact',
    path: '/contact-us'
  }
];

const PARTNERSHIP = [
  {
    id: 1,
    name: 'Become a Marketers',
    path: '/'
  },
  {
    id: 2,
    name: 'Become an Agent',
    path: '/'
  },
  {
    id: 3,
    name: 'Explore our Swag Store',
    path: '/swag-store'
  }
];

export {
  FOOTER_NAV,
  PARTNERSHIP
};
