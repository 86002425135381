import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import Loader from '../components/backdrop/Loader';
import ScrollToTop from './ScrollToTop';

/**
 * Layouts for Routes
 */

import PublicRoute from '../layouts/Layouts';
import Error404 from '../pages/Errors/Error404';
import { publicRoutes, privateRoutes } from './apps/config';
import PrivateRoute from './private/PrivateRoute';

const Layouts = () => {
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <Switch>
          {privateRoutes.map(({
            layout, path,
            component, authPath
          }) => (
            <PrivateRoute
              key={path}
              exact
              path={path}
              layout={layout}
              authPath={authPath}
              component={component}
            />
          ))}
          {publicRoutes.map(({
            layout, path,
            component
          }) => (
            <PublicRoute
              key={path}
              path={path}
              exact
              layout={layout}
              component={component}
            />
          ))}
          <PublicRoute
            exact
            component={Error404}
          />
        </Switch>
      </Suspense>
    </>
  );
};

export default Layouts;
