import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserUtils from '../../../utils/functions/users/userUtils';
import { DTabs } from './Styles';

const useStyles = makeStyles((theme) => (DTabs(theme)));

const isCooperative = UserUtils.getUserData('cooperative');

export const coopDashboard = () => {
  const coopType = UserUtils.userAuthType(isCooperative);
  switch (coopType) {
    case 'COOPEAST':
      return '/my-cooperative-account/overview';
    case 'MEMCOS':
      return '/mtn-memcos/dashboard';
    default:
      return '/co-operatives/login';
  }
};

export default function NavTabs({ LinkData }) {
  const classes = useStyles();
  const { app } = useSelector((state) => state.platform);

  return (
    <div className="appbar">
      <nav className={classes.root} style={{ background: app?.theme?.secondary?.main }}>
        <ul className={classes.list}>
          {LinkData.map(({ name, path }) => (
            <li key={name} className="navtabs">
              <NavLink
                to={path}
                activeStyle={path !== '/'
                  ? { color: app?.theme?.primary?.main }
                  : {}}
                title={name}
              >
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

/** *
 * Easy fix for tab theme
 */

NavTabs.defaultProps = {
  LinkData: []
};
