import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';
import product from '../../images/category.svg';
import noSignal from '../../images/no-signal.svg';
import { Style } from './Style';
import { handleError } from '../../errors/ErrorMgs';

const useStyles = makeStyles((theme) => Style(theme));

const NetWorkError = ({ classes, error }) => {
  const [errorMgs, setErrorMgs] = useState();

  useEffect(() => {
    const { message } = handleError(error);
    setErrorMgs(message);
  }, [error]);

  return (
    <div className={classes.root}>
      <div>
        <img
          className={classes.block}
          height="150"
          src={noSignal}
          alt="Empty List"
        />
      </div>
      <Typography variant="body1">{errorMgs}</Typography>
      <div>
        <Button
          variant="contained"
          className={classes.block}
          color="secondary"
          fullWidth
        >
          Retry
        </Button>
      </div>
    </div>
  );
};

export const EmptyList = ({
  color, link, text, error,
  imageFile, variant, height
}) => {
  const classes = useStyles();

  if (error) {
    return (
      <NetWorkError
        error={error}
        classes={classes}
      />
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <img
          className={classes.block}
          height={height}
          src={imageFile}
          alt="Empty List"
        />
      </div>
      <div>
        <Button
          variant={variant}
          className={classes.block}
          color={color}
          fullWidth
          href={link}
        >
          {text}
        </Button>
      </div>
    </div>
  );
};

EmptyList.defaultProps = {
  color: 'primary',
  link: '#',
  height: '100',
  text: '',
  variant: 'text',
  imageFile:
    'https://res.cloudinary.com/accelerar/image/upload/v1601221590/FrontendAssests/Carousels/folder_x3tloc.svg'
  // imageFile: emptyLogo,
};

/** *
 * @deprecated component
 */

export const ProductEmplist = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img height="150" src={product} alt="Products" />
      <Typography variant="body1">{title}</Typography>
    </div>
  );
};
