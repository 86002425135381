import React from 'react';
import { Grid, Box } from '@material-ui/core/';
import Skeleton from '@material-ui/lab/Skeleton';

const data = [];

export function Media(props) {
  const { loading = true, block } = props;

  return (
    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
      {(loading ? Array.from(new Array(block)) : data).map(() => (
        <Box
          key={Math.random()}
          height={300}
          width={300}
          marginRight={2}
          my={1}
        >
          <Skeleton variant="rect" width={300} height={180} />
          <Box pt={1}>
            <Skeleton />
            <Skeleton width="90%" />
          </Box>
        </Box>
      ))}
    </Grid>
  );
}

Media.defaultProp = {
  block: 3
};
