import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography/';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Box, Chip, Divider } from '@material-ui/core';
import AddToCartForm from '../AddToCartForm';
import { formatter } from '../../../utils/formatters/formatter';
import { StoreInCart } from '../../../GoogleAnalytics/Events/Buying';
import ProductUtils from '../../../utils/functions/products/productUtils';
import Style from './Style';

const useStyles = makeStyles((theme) => (Style(theme)));

const getDiscount = (itemUnits) => (
  Math.ceil(((itemUnits.marketPrice - itemUnits.price)
    / itemUnits.marketPrice) * 100)
);
const ProductCard = ({ item }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const getUnits = () => {
    return item?.platform[0]?.variant[0];
  };

  const itemUnits = getUnits();

  const viewDetails = () => {
    history.push(`/product-details/${item.category}/${item.title}/${item._id}`);
  };

  return (
    <>
      <AddToCartForm
        item={item}
        addForm={open}
        setAddForm={setOpen}
      />
      <Card className={classes.root}>
        <CardActionArea onClick={viewDetails}>
          <CardMedia
            component="img"
            alt={item.title}
            height="140"
            image={item.image[0]}
            title={item.title}
          />
          <CardContent>
            <Typography className={classes.itemTitle} variant="h6" component="h3">
              {ProductUtils.slicedText(item.title)}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              className={classes.box}
              justifyContent="space-between"
            >
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
              >
                {formatter.format(itemUnits.price)}
              </Typography>
              <Typography
                className={classes.marketPrice}
                variant="body1"
                color="textSecondary"
                component="p"
              >
                {formatter.format(itemUnits.marketPrice || 0)}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              className={classes.box}
              justifyContent="space-between"
            >
              <Chip
                size="small"
                label={itemUnits.unit}
                color="default"
              />
              {
                getDiscount(itemUnits) !== 0 && (
                  <Typography
                    className={classes.discount}
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    -
                    {getDiscount(itemUnits)}
                    %
                  </Typography>
                )
              }
            </Box>
          </CardContent>
        </CardActionArea>
        <Divider />
        <CardActions className={classes.btn}>
          <Button
            onClick={() => {
              setOpen(true);
              StoreInCart();
            }}
            variant="contained"
            color="primary"
            disabled={item.quantity === 0}
          >
            {item.quantity === 0
              ? 'Sold Out' : '  Add to cart'}
          </Button>
        </CardActions>
      </Card>

    </>
  );
};

export default ProductCard;
