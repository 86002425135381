import React from 'react';
import { Badge, makeStyles } from '@material-ui/core';
import {
  ShoppingCartOutlined as ShoppingCartIcon,
} from '@material-ui/icons/';
import { useSelector } from 'react-redux';
import Header from '../../../shared/Header/Header';
import CartDrawer from '../../../components/cart/Cart';
import {
  activeMenu,
  LOGGEDIN_USER_NAV,
  LOGGEDOUT_USER_NAV,
  navLinksData, userRoutes
} from './dataList';
import { appBrands } from '../../../constants/Brands';
import CoopSearchBar from '../../../components/searchBar/SearchBar';
import { CartStyles } from '../../../shared/Header/Mobile/sidenav/Styles';

const useStyles = makeStyles((theme) => CartStyles(theme));

const Carts = ({ cartItem }) => {
  const classes = useStyles();
  return (
    <CartDrawer
      icon={(
        <Badge badgeContent={cartItem.length} color="secondary">
          <ShoppingCartIcon className={classes.icon2} />
        </Badge>
      )}
    />
  );
};

const MainHeader = () => {
  const { products } = useSelector((state) => state.products);
  const { cart: cartItem } = useSelector((state) => state.cart);

  return (
    <Header
      cartDrawer={<Carts cartItem={cartItem} />}
      active={activeMenu}
      routes={userRoutes}
      navList={navLinksData}
      searchBar={(
        <CoopSearchBar
          urls={{
            details: '/coopeast/products',
            all: '/coopeast/products/all'
          }}
          data={products}
        />
      )}
      customLogo={appBrands.customLogo}
      loggedInUserNav={LOGGEDIN_USER_NAV}
      loggedOutUserNav={LOGGEDOUT_USER_NAV}
    />
  );
};

export default MainHeader;
