import React, { useState, createContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getData } from '../../redux/reducers/cooperatives';

/**
 * using a global notification state for user signup and sign in
 */

export const contextApi = createContext();

const ContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(false);
  const [prevRoute, setPrevRoute] = useState('');
  const [isVerified, setIsVerified] = useState('');
  const [authAccess, setAuthAccess] = useState({
    isNotAuthentiated: false,
    type: '',
    status: '',
    location: ''
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  return (
    <contextApi.Provider
      value={{
        notifications,
        setNotifications,
        authAccess,
        setAuthAccess,
        prevRoute,
        setPrevRoute,
        isVerified,
        setIsVerified,
      }}
    >
      {children}
    </contextApi.Provider>
  );
};

export default ContextProvider;
