import React from 'react';
import {
  makeStyles, Grid, Hidden, Container
} from '@material-ui/core/';
import { Style } from './Style';
import EmailSubscriber from './EmailSubscriber/EmailSubscriber';
import SubScribeImg from '../../../images/subscribe.jpg';

const useStyles = makeStyles((theme) => Style(theme));

function SubScribe() {
  const classes = useStyles();

  return (
    <Container className={classes.root} component="section">
      <Grid alignContent="center" container className={classes.grid}>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <EmailSubscriber />
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <img
              src={SubScribeImg}
              alt="call to action"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SubScribe;
