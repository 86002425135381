import ReactGA from 'react-ga';
import { appMode } from '../GoogleAnalytics';

const isProduction = appMode();

export const StoreInCart = () => {
  if (!isProduction) return null;
  return ReactGA.event({
    category: 'Buying',
    action: 'Add to cart'
  });
};

export const BuyNow = () => {
  if (!isProduction) return null;
  return ReactGA.event({
    category: 'Buying',
    action: 'BUY NOW'
  });
};

export const Shopping = () => {
  if (!isProduction) return null;
  return ReactGA.event({
    category: 'Buying',
    action: 'SHOP'
  });
};

export const SearchQuery = () => {
  if (!isProduction) return null;
  return ReactGA.event({
    category: 'Buying',
    action: 'SEARCH'
  });
};

export const MakePayment = () => {
  if (!isProduction) return null;
  return ReactGA.event({
    category: 'Buying',
    action: 'MAKE PAYMENT'
  });
};
