import React, {
  useEffect,
  useState,
  useContext,
  useRef
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
} from '@material-ui/core';
import {
  PersonOutline as PersonOutlineIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  ShoppingBasketOutlined as ShoppingBasketIcon,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { contextApi } from '../../../components/context/Context';
import { DLinkStyles } from './Styles';
import LinksMenu from './LinksMenu';
import UserUtils from '../../../utils/functions/users/userUtils';

const useStyles = makeStyles((theme) => (DLinkStyles(theme)));

const DesktopHeaderLinks = ({ routes, shopUrl }) => {
  const classes = useStyles();
  const { notifications } = useContext(contextApi);
  const { user } = useSelector((state) => state.userAuth);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const userName = user?.userName;
  const name = user?.name;
  const { userLogout } = UserUtils;
  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const shopping = () => history.push(shopUrl);

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open, notifications]);

  return (
    <div style={{ margin: '-.2rem' }}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.login}
        startIcon={<PersonOutlineIcon className={classes.icon} />}
      >
        {userName || name ? (
          <span>{(userName || name).slice(0, 6)}</span>
        ) : (
          'LOGIN'
        )}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <LinksMenu
        anchorRef={anchorRef}
        classes={classes}
        handleClose={handleClose}
        handleListKeyDown={handleListKeyDown}
        handleLogout={userLogout}
        name={name}
        userName={userName}
        open={open}
        routes={routes}
      />
      <Button
        onClick={shopping}
        className={classes.login}
        startIcon={<ShoppingBasketIcon className={classes.icon} />}
      >
        SHOP
      </Button>
    </div>
  );
};

export default DesktopHeaderLinks;
