import React from 'react';
import {
  ContactPhone,
  ShoppingBasketOutlined,
  PersonOutline,
  Help,
  Share,
  Business
} from '@material-ui/icons';
import { coopDashboard } from '../../../shared/Header/Desktop/Tabs';
import { memCosRoutes } from '../../../routes/routes.path';

const {
  MC_CONTACT,
  MC_HELP_CENTER,
  MC_PROFILE, MC_ORDERS,
  MC_DASHBOARD, MC_LOGIN
} = memCosRoutes;

const LOGGEDOUT_USER_NAV = [
  {
    id: 3,
    name: 'Shop Now',
    path: '/mtn-memcos-cooperative/all',
    icon: <ShoppingBasketOutlined />
  },
  {
    id: 70,
    name: 'Contact',
    path: MC_CONTACT,
    icon: <ContactPhone />
  },
  {
    id: 7,
    name: 'Help Center',
    path: MC_HELP_CENTER,
    icon: <Help />
  }
];

const LOGGEDIN_USER_NAV = [
  {
    id: 70,
    name: 'My Account',
    path: MC_PROFILE,
    icon: <PersonOutline />
  },
  {
    id: 38,
    name: 'Shop Now',
    path: '/mtn-memcos-cooperative/all',
    icon: <ShoppingBasketOutlined />
  },
  {
    id: 22,
    name: 'My Orders',
    path: MC_ORDERS,
    icon: <Share />
  },
  {
    id: 244,
    name: 'My Co-operative',
    path: MC_DASHBOARD,
    icon: <Business />
  },
  {
    id: 70,
    name: 'Contact',
    path: MC_CONTACT,
    icon: <ContactPhone />
  },
  {
    id: 7,
    name: 'Help Center',
    path: MC_HELP_CENTER,
    icon: <Help />
  }
];

const activeMenu = {
  color: '#000'
};

const navLinksData = [
  {
    name: 'Home',
    path: '/'
  },
  {
    name: 'Shop',
    path: '/mtn-memcos-cooperative/all'
  },
  {
    name: 'Help Center',
    path: MC_HELP_CENTER
  },
  {
    name: 'Contact Us',
    path: MC_CONTACT
  },
  {
    name: 'My Cooperative',
    path: coopDashboard()
  },
];

const userRoutes = {
  userLogin: MC_LOGIN,
  userProfile: MC_PROFILE,
  userOrders: MC_ORDERS,
  shopUrl: '/mtn-memcos-cooperative/all',
  productView: '/mtn-memcos-cooperative',
  home: '##'
};
export {
  activeMenu,
  userRoutes,
  navLinksData,
  LOGGEDIN_USER_NAV,
  LOGGEDOUT_USER_NAV
};
