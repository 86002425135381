import React from 'react';
import { appBrands } from '../../constants/Brands';

const Loader = () => {
  return (
    <div className="full-page-loader">
      <img
        width="100"
        src={appBrands.customLogo}
        alt="Food Crowdy"
      />
    </div>
  );
};

export default Loader;
