import React from 'react';
import ENV_KEYS from '../../../../config';

const EmailSubscriber = () => {
  return (
    <iframe
      title="email"
      width="540"
      height="505"
      src={ENV_KEYS.SUBSCRIBE_FRAME}
      frameBorder="0"
      scrolling="0"
      allowFullScreen
      style={{
        display: 'block',
        margin: '0 auto',
        maxWidth: '100%',
        maxHeight: '70%',
        overflow: 'hidden'
      }}
    />
  );
};

export default EmailSubscriber;
