import ReactGA from 'react-ga';
import ENV_KEYS from '../config';
import UserUtils from '../utils/functions/users/userUtils';

/** *
 **** GOOGLE ANALYTICS SETUP FOR WEB TRACKING*****
 *
 */

export const appMode = () => process.env.NODE_ENV === 'production';

const { getUserData } = UserUtils;

const userId = getUserData('id');
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(ENV_KEYS.GOOGLE_ANALYTICS, {
    debug: false,
    titleCase: false,
    gaOptions: {
      userId: userId || 'visitor',
    },
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
}
